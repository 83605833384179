<template>
  <v-container :id="printId">
    <v-col cols="12">
      <h1 class="survey-title">
        {{ survey.title }}
      </h1>
      <p>
        恐れ入りますが、アンケートへのご協力をお願いいたします。
      </p>
      <hr />
    </v-col>
    <v-row class="d-flex" align="center" justify="center">
      <template v-for="(question, index) in survey.questions">
        <v-col cols="12" :key="question.id" class="py-0">
          <div>
            <print-question
              :question="question"
              :index="index + 1"
            ></print-question>
          </div>
        </v-col>
      </template>
      <v-col cols="12">
        <hr style="margin-top: 10px; margin-bottom: 10px;" />
        <p>*の項目は回答必須です。</p>
        <p>
          アンケートは以上です。<br />
          ご協力いただきありがとうございました。
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrintQuestion from "./PrintQuestion.vue";

export default {
  name: "PrintMe",
  props: ["printId", "survey"],
  components: { PrintQuestion },
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {},
  async mounted() {},
};
</script>

<style scoped></style>
