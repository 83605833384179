<template>
  <v-row class="d-flex" @click="goToSurveyDetail('edit')">
    <v-col cols="12">
      <v-card elevation="2">
        <v-card-title class="pa-2">{{ survey.title }}</v-card-title>
        <div class="count-container">
          <p class="count-title mb-0">回答数</p>
          <p class="count-number mb-0">
            {{
              survey.submissions.filter((sub) => sub.answers.length > 0).length
            }}
          </p>
        </div>

        <div class="sub-info-container ma-2">
          <p v-if="survey.isActive" class="sub-info-label-active ma-0">
            公開中
          </p>
          <p v-if="!survey.isActive" class="sub-info-label-stop ma-0">
            停止中
          </p>
          <p class="sub-info-date ma-0">作成日: {{ formattedDate }}</p>
        </div>

        <hr class="survey-item-separator mt-2 mb-2" />

        <div class="action-button-container mt-4">
          <div class="button-item" @click.stop="goToSurveyDetail('edit')">
            <v-img
              alt="編集するボタン"
              class="shrink mx-auto mb-2"
              max-width="25"
              src="../../assets/surve-action-edit.png"
            />
            <p class="button-title mb-2">編集する</p>
          </div>
          <div class="button-item" @click.stop="goToSurveyDetail('share')">
            <v-img
              alt="共有するボタン"
              class="shrink mx-auto mb-2"
              max-width="25"
              src="../../assets/surve-action-share.png"
            />
            <p class="button-title mb-2">共有する</p>
          </div>
          <div class="button-item" @click.stop="goToSurveyDetail('analytics')">
            <v-img
              alt="分析するボタン"
              class="shrink mx-auto mb-2"
              max-width="25"
              src="../../assets/surve-action-analytics.png"
            />
            <p class="button-title mb-2">分析する</p>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { formattedDate } from "../../plugins/date-util.js";

export default {
  name: "SurveyItem",
  props: ["survey"],
  components: {},
  data: () => ({}),
  computed: {
    formattedDate() {
      return formattedDate(this.survey.createdAt);
    },
  },
  validations: {},
  methods: {
    goToSurveyDetail(tab) {
      this.$emit("goToSurveyDetail", this.survey, tab);
    },
  },
  mounted() {},
};
</script>

<style scoped>
.count-container {
  text-align: center;
}
.count-title {
  color: #042a2b;
  font-size: 12px;
}
.count-number {
  color: #ee6352;
  font-size: 24px;
  font-weight: bold;
}
.sub-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub-info-label-active {
  background-color: #ee6352;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
.sub-info-label-stop {
  background-color: #042a2b;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
.sub-info-date {
  font-size: 10px;
  color: #042a2b;
}
.action-button-container {
  display: flex;
  width: 100%;
}
.button-item {
  flex: 1;
  text-align: center;
  margin: auto;
}
.button-title {
  font-size: 12px;
  color: #042a2b;
}
.survey-item-separator {
  height: 1px;
  background-color: #f6f6f6;
  border: 0;
}
</style>
