import repository from "../repository";

export default {
  setupStripe() {
    return repository.get(`setupStripe`);
  },
  getPortal() {
    return repository.get(`stripePortal`);
  },
  createCheckoutSession(payload) {
    return repository.post(`createCheckoutSession`, payload);
  },
};
