<template>
  <div
    v-bind:class="
      $vuetify.breakpoint.smAndDown
        ? 'register-button-area-mobile'
        : 'register-button-area'
    "
  >
    <v-btn
      @click="goToSignup"
      elevation="3"
      large
      color="primary"
      class="register-button"
      :height="height"
      :width="width"
    >
      <span color="white" v-bind:style="{ fontSize: fontSize + 'px' }">
        無料で会員登録する
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "RegisterButton",
  props: {
    compName: {
      type: String,
      require: true,
      default: "",
    },
  },
  methods: {
    goToSignup() {
      this.$emit("goToSignup", this.compName);
    },
  },
  computed: {
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 16;
        case "sm":
          return 16;
        case "md":
          return 28;
        case "lg":
          return 28;
        case "xl":
          return 28;
        default:
          return 0;
      }
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 44;
        case "sm":
          return 44;
        case "md":
          return 80;
        case "lg":
          return 80;
        case "xl":
          return 80;
        default:
          return 0;
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 180;
        case "sm":
          return 180;
        case "md":
          return 400;
        case "lg":
          return 400;
        case "xl":
          return 400;
        default:
          return 0;
      }
    },
  },
};
</script>

<style scoped>
.register-button-area {
  position: relative;
  height: 215px;
}
.register-button-area-mobile {
  position: relative;
  height: 84px;
}
.register-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
