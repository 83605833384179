<template>
  <div class="targets-area pt-6 pb-3 pt-md-12 pb-md-6">
    <div
      class="targets-title-area mb-3 mb-md-12"
      v-bind:class="
        $vuetify.breakpoint.smAndDown ? 'targets-title-mobile' : 'targets-title'
      "
    >
      <h2>あらゆる業種・シーンで</h2>
    </div>
    <v-container pt-0>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="4" class="pa-0">
          <div
            v-bind:class="
              $vuetify.breakpoint.smAndDown
                ? 'target-item-title-mobile'
                : 'target-item-title'
            "
          >
            <v-img
              alt="習い事教室アイコン"
              class="shrink target-image"
              contain
              src="../../assets/ic_school.png"
              max-height="75"
            />
            <div class="mt-4">
              <p>習い事教室</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="pa-0">
          <div
            v-bind:class="
              $vuetify.breakpoint.smAndDown
                ? 'target-item-title-mobile'
                : 'target-item-title'
            "
          >
            <v-img
              alt="整体・マッサージアイコン"
              class="shrink target-image"
              contain
              src="../../assets/ic_people.png"
              max-height="75"
            />
            <div class="mt-4">
              <p>整体・マッサージ</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="pa-0">
          <div
            v-bind:class="
              $vuetify.breakpoint.smAndDown
                ? 'target-item-title-mobile'
                : 'target-item-title'
            "
          >
            <v-img
              alt="エステ・ネイルアイコン"
              class="shrink target-image"
              contain
              src="../../assets/ic_local_florist.png"
              max-height="75"
            />
            <div class="mt-4">
              <p>エステ・ネイル</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="pa-0">
          <div
            v-bind:class="
              $vuetify.breakpoint.smAndDown
                ? 'target-item-title-mobile'
                : 'target-item-title'
            "
          >
            <v-img
              alt="歯医者・病院アイコン"
              class="shrink target-image"
              contain
              src="../../assets/ic_local_hospital.png"
              max-height="75"
            />
            <div class="mt-4">
              <p>歯医者・病院</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="pa-0">
          <div
            v-bind:class="
              $vuetify.breakpoint.smAndDown
                ? 'target-item-title-mobile'
                : 'target-item-title'
            "
          >
            <v-img
              alt="美容院アイコン"
              class="shrink target-image"
              contain
              src="../../assets/ic_face.png"
              max-height="75"
            />
            <div class="mt-4">
              <p>美容院</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" class="pa-0">
          <div
            v-bind:class="
              $vuetify.breakpoint.smAndDown
                ? 'target-item-title-mobile'
                : 'target-item-title'
            "
          >
            <v-img
              alt="飲食店アイコン"
              class="shrink target-image"
              contain
              src="../../assets/ic_local_dining.png"
              max-height="75"
            />
            <div class="mt-4">
              <p>飲食店</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Targets",
};
</script>

<style scoped>
.targets-area {
  background-color: #f6f8f9;
}
.targets-title-area {
  text-align: center;
}
.targets-title-area h2 {
  display: inline;
  color: #042a2b;
  border-bottom: medium solid #042a2b;
}
.targets-title-mobile h2 {
  font-size: 20px;
}

.target-item-title {
  text-align: center;
  color: #042a2b;
}

.target-item-title-mobile {
  width: 200px;
  margin: 0 auto;
}
.target-item-title-mobile div {
  display: inline-block;
  vertical-align: middle;
}
.target-item-title-mobile .target-image {
  width: 30px;
  margin-right: 12px;
}
</style>
