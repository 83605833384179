<template>
  <div class="answer_top_container">
    <start-answer
      v-if="answerState === 'start'"
      :survey="survey"
      :isSurveyAvailable="isSurveyAvailable"
      @onStartSurvey="onStartSurvey"
    ></start-answer>
    <collect-answer
      v-else-if="answerState === 'question'"
      :currentProgress="currentProgress"
      :question="currentQuestion"
      @goToNext="goToNext"
    ></collect-answer>
    <end-answer v-else-if="answerState === 'end'"></end-answer>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
import CollectAnswer from "../components/answer/CollectAnswer.vue";
import EndAnswer from "../components/answer/EndAnswer.vue";
import StartAnswer from "../components/answer/StartAnswer.vue";
const PublicRepository = RepositoryFactory.get("public");

export default {
  name: "PublicAnswerTop",
  metaInfo: {
    meta: [{ name: "robots", content: "noindex" }],
  },
  components: { StartAnswer, CollectAnswer, EndAnswer },
  data: () => ({
    answerState: "start", // start, question, end
    currentQuestionIndex: 0,
    currentQuestion: {},
    survey: {},
    isSurveyAvailable: true,
    requestResult: {},
  }),
  computed: {
    currentProgress() {
      return (this.currentQuestionIndex / this.survey.questions.length) * 100;
    },
  },
  validations: {},
  methods: {
    onStartSurvey() {
      this.currentQuestion = this.survey.questions[this.currentQuestionIndex];
      this.answerState = "question";
    },
    goToNext(value) {
      // If question is skipable, will be undefined
      if (value !== undefined) {
        this.appendAnswer(value);
      }
      this.currentQuestionIndex = this.currentQuestionIndex + 1;
      if (this.survey.questions.length > this.currentQuestionIndex) {
        this.currentQuestion = this.survey.questions[this.currentQuestionIndex];
      } else {
        this.submitAnswers();
        this.answerState = "end";
      }
    },
    appendAnswer(value) {
      const answer = {};
      answer.questionId = this.currentQuestion.id;

      switch (this.currentQuestion.type) {
        case "text_question":
          answer.type = "text_answer";
          answer.textAnswerRequest = {
            answerText: value,
          };
          break;
        case "number_question":
          answer.type = "number_answer";
          answer.numberAnswerRequest = {
            answerNumber: value,
          };
          break;
        case "selection_question":
          var result = [];
          if (this.currentQuestion.isMultiple) {
            result = value;
          } else {
            result.push(value);
          }
          answer.type = "selection_answer";
          answer.selectionAnswerRequest = {
            itemIds: result,
          };
          break;
      }

      this.requestResult.answers.push(answer);
    },
    submitAnswers() {
      this.$ga.event("submit-answers-complete", "click");

      PublicRepository.submit(this.requestResult)
        .then(() => {})
        .catch(() => {});
    },
    accessLog(id) {
      const payload = { surveyId: id };
      PublicRepository.log(payload)
        .then(() => {})
        .catch(() => {});
    },
  },
  async mounted() {
    this.accessLog(this.$route.params.id);
    PublicRepository.get(this.$route.params.id)
      .then((res) => {
        this.survey = res.data.body;

        this.requestResult = {};
        this.requestResult.surveyId = String(this.$route.params.id);
        this.requestResult.answers = [];
      })
      .catch(() => {
        this.isSurveyAvailable = false;
      });
  },
};
</script>

<style scoped>
.answer_top_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
</style>
