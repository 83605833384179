<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title class="pb-0">
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field v-model="internalUserName" required></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          閉じる
        </v-btn>
        <v-btn color="primary" @click="saveClicked">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    type: {
      type: String,
      require: true,
      default: "add", // 'add' or 'edit'
    },
    userName: {
      type: String,
      require: false,
      default: "",
    },
  },
  data: function() {
    return {
      internalUserName: this.userName,
    };
  },
  watch: {
    userName: function(val) {
      this.internalUserName = val;
    },
  },
  computed: {
    title() {
      return this.type == "add" ? "氏名の追加" : "氏名の編集";
    },
  },
  methods: {
    closeDialog() {
      this.internalUserName = this.userName;
      this.$emit("close");
    },
    saveClicked() {
      this.$emit("save", this.internalUserName);
      this.internalUserName = this.userName;
    },
  },
};
</script>

<style scoped>
.headline {
  color: #042a2b;
  font-size: 16px !important;
}
</style>
