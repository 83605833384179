<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="dialog-background">
      <v-toolbar dark color="primary" class="px-2">
        <v-btn icon dark @click="closeDialog(true)">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-0">{{ naviTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="actionType === 'edit'">
          <v-toolbar-items>
            <v-icon @click="deleteQuestion()">mdi-trash-can</v-icon>
          </v-toolbar-items>
        </template>
      </v-toolbar>
      <v-col cols="12">
        <h2 class="section-title mb-3">質問文</h2>
        <v-textarea
          v-model="editingQuestion.questionTitle"
          placeholder="質問文を入力してください
例: 「体験入会の感想を教えて下さい」"
          outlined
          rows="3"
          background-color="white"
          :error-messages="errors.questionTitle"
          @input="updateErrorMessages('questionTitle')"
          @blur="updateErrorMessages('questionTitle')"
        ></v-textarea>
        <template v-if="questionType === 'text_question'">
          <h2 class="section-title mb-3">最大回答文字数</h2>
          <v-text-field
            v-model="editingQuestion.maxLength"
            placeholder="文字数を入力してください。例: 100"
            outlined
            type="number"
            suffix="文字"
            background-color="white"
            :error-messages="errors.maxLength"
            @input="updateErrorMessages('maxLength')"
            @blur="updateErrorMessages('maxLength')"
          ></v-text-field>
        </template>
        <template v-else-if="questionType === 'number_question'">
          <h2 class="section-title mb-3">最小数値</h2>
          <v-text-field
            v-model="editingQuestion.minNumber"
            placeholder="最小数値を入力してください。例: 0"
            outlined
            type="number"
            background-color="white"
            :error-messages="errors.minNumber"
            @input="updateErrorMessages('minNumber')"
            @blur="updateErrorMessages('minNumber')"
          ></v-text-field>
          <h2 class="section-title mb-3">最大数値</h2>
          <v-text-field
            v-model="editingQuestion.maxNumber"
            placeholder="最大数値を入力してください。例: 100"
            outlined
            type="number"
            background-color="white"
            :error-messages="errors.maxNumber"
            @input="updateErrorMessages('maxNumber')"
            @blur="updateErrorMessages('maxNumber')"
          ></v-text-field>
        </template>
        <template v-else-if="questionType === 'selection_question'">
          <h2 class="section-title mb-3">選択肢一覧</h2>
          <template v-for="(item, i) in editingQuestion.selectionItems">
            <v-text-field
              :key="i"
              outlined
              placeholder="選択肢を入力してください。例: バナナ"
              hide-details="auto"
              v-model="item.itemTitle"
              class="mt-2"
              background-color="white"
              @input="updateErrorMessages('selectionItems')"
              @blur="updateErrorMessages('selectionItems')"
            >
              <v-icon
                @click="deleteOneItem(i)"
                slot="append-outer"
                color="primary"
              >
                mdi-trash-can
              </v-icon>
            </v-text-field>
          </template>
          <p class="mt-1 custom-error" v-if="errors.selectionItems.length > 0">
            {{ errors.selectionItems }}
          </p>
          <div class="mx-auto text-center mt-4">
            <v-icon
              size="50"
              class="mx-auto"
              @click="addOneItem"
              color="primary"
            >
              mdi-plus-circle
            </v-icon>
          </div>
          <h2 class="section-title mb-3">選択方式</h2>
          <v-radio-group v-model="editingQuestion.isMultiple" mandatory>
            <v-radio label="単数選択" :value="false"></v-radio>
            <v-radio label="複数選択" :value="true"></v-radio>
          </v-radio-group>
        </template>
        <h2 class="section-title mb-3">回答必須</h2>
        <v-switch
          v-model="editingQuestion.isRequired"
          inset
          :label="
            `${
              editingQuestion.isRequired ? '回答は必須です' : '回答は任意です'
            }`
          "
        ></v-switch>
      </v-col>
    </v-card>

    <v-btn
      fixed
      bottom
      right
      left
      color="primary"
      height="45px"
      max-width="450px"
      width="90%"
      class="mx-auto"
      @click="onSaveQuestion"
    >
      保存する
    </v-btn>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          本当にこの質問を削除してよろしいですか？
        </v-card-title>
        <v-card-text>
          この質問に対する回答も同時に削除されます。取り戻しはできません。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">
            キャンセル
          </v-btn>
          <v-btn color="primary" text @click="executeDeleteQuestion">
            確認して実行
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "../../api/repositoryFactory";
const QuestionRepository = RepositoryFactory.get("questions");
const QuestionItemRepository = RepositoryFactory.get("items");

export default {
  name: "EditQuestionDialog",
  props: {
    dialog: {
      type: Boolean,
    },
    questionType: {
      type: String,
      require: true,
      default: "",
    },
    actionType: {
      type: String,
      require: true,
      default: "add", // 'add' or 'edit'
    },
    question: {
      type: Object,
      require: false,
      default() {
        return {};
      },
    },
    surveyId: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      editingQuestion: {
        isRequired: true,
        ...this.question,
      },
      snackbar: false,
      snackMessage: "",
      deleteList: [],
      deleteDialog: false,
      errors: {
        questionTitle: "",
        maxLength: "",
        minNumber: "",
        maxNumber: "",
        selectionItems: "",
      },
    };
  },
  watch: {
    question: function(val) {
      this.editingQuestion = val;
    },
  },
  computed: {
    naviTitle() {
      const action = this.actionType === "add" ? "追加" : "編集";
      switch (this.questionType) {
        case "text_question":
          return "文章質問の" + action;
        case "number_question":
          return "数値質問の" + action;
        case "selection_question":
          return "選択質問の" + action;
      }
      return "";
    },
  },
  validations: {
    editingQuestion: {
      isValidQuestion,
    },
  },
  methods: {
    closeDialog(needToRefresh) {
      if (this.actionType === "add") {
        this.editingQuestion = {};
      }
      for (const p in this.errors) {
        this.errors[p] = "";
      }
      this.$emit("close", needToRefresh);
    },
    onSaveQuestion() {
      this.updateErrorMessages("all");

      this.$v.editingQuestion.$touch();
      if (this.$v.editingQuestion.$invalid) {
        return;
      }

      if (this.actionType === "add") {
        this.$ga.event("add-questions", "self", "1");
        this.createNewQuestion();
      } else if (this.actionType == "edit") {
        this.$ga.event("update-question");
        this.updateCurrentQuestion();
      }
    },
    updateCurrentQuestion() {
      const payload = this.updateQuestionInfoPayload();
      QuestionRepository.edit(payload)
        .then(() => {
          if (this.questionType === "selection_question") {
            this.updateQuestionItems();
          } else {
            this.closeDialog(true);
          }
        })
        .catch(() => {
          this.snackMessage =
            "質問の編集に失敗しました。入力内容をお確かめ下さい。";
          this.snackbar = true;
        });
    },
    createNewQuestion() {
      const payload = this.createQuestionInfoPayload();
      QuestionRepository.create(payload)
        .then((response) => {
          if (this.questionType === "selection_question") {
            const questionId = response.data.body.id;
            this.addQuestionItems(questionId);
          } else {
            this.$router.replace(`/surveys/${this.$route.params.id}`);
          }
        })
        .catch(() => {
          this.snackMessage =
            "質問の追加に失敗しました。入力内容をお確かめ下さい。";
          this.snackbar = true;
        });
    },
    createQuestionInfoPayload() {
      const result = {
        type: this.questionType,
        surveyId: this.surveyId,
        isRequired: this.editingQuestion.isRequired,
      };
      switch (this.questionType) {
        case "text_question":
          result.textQuestionRequest = {
            questionTitle: this.editingQuestion.questionTitle,
            maxLength: this.editingQuestion.maxLength,
          };
          break;
        case "number_question":
          result.numberQuestionRequest = {
            questionTitle: this.editingQuestion.questionTitle,
            minNumber: this.editingQuestion.minNumber,
            maxNumber: this.editingQuestion.maxNumber,
            minTitle: "",
            maxTitle: "",
          };
          break;
        case "selection_question":
          result.selectionQuestionRequest = {
            questionTitle: this.editingQuestion.questionTitle,
            isMultiple: this.editingQuestion.isMultiple,
          };
          break;
      }
      return result;
    },
    updateQuestionInfoPayload() {
      const result = {
        type: this.questionType,
        surveyId: this.surveyId,
        questionId: this.editingQuestion.id,
        isRequired: this.editingQuestion.isRequired,
      };
      switch (this.questionType) {
        case "text_question":
          result.textQuestionRequest = {
            questionTitle: this.editingQuestion.questionTitle,
            maxLength: this.editingQuestion.maxLength,
          };
          break;
        case "number_question":
          result.numberQuestionRequest = {
            questionTitle: this.editingQuestion.questionTitle,
            minNumber: this.editingQuestion.minNumber,
            maxNumber: this.editingQuestion.maxNumber,
            minTitle: "",
            maxTitle: "",
          };
          break;
        case "selection_question":
          result.selectionQuestionRequest = {
            questionTitle: this.editingQuestion.questionTitle,
            isMultiple: this.editingQuestion.isMultiple,
          };
          break;
      }
      return result;
    },
    executeDeleteQuestion() {
      this.$ga.event("delete-question");
      this.deleteDialog = false;
      const payload = {
        surveyId: this.surveyId,
        questionId: this.question.id,
      };
      QuestionRepository.delete(payload)
        .then(() => {
          this.closeDialog(true);
        })
        .catch(() => {
          this.snackMessage =
            "質問の削除に失敗しました。時間をおいて再度お試しください。";
          this.snackbar = true;
        });
    },
    deleteQuestion() {
      this.deleteDialog = true;
    },
    addQuestionItems(questionId) {
      const payload = {
        surveyId: this.surveyId,
        questionId: questionId,
        addList: this.editingQuestion.selectionItems
          .filter((item) => item.itemTitle !== "")
          .map((item) => item.itemTitle),
      };
      QuestionItemRepository.bulk(payload)
        .then(() => {
          this.$router.replace(`/surveys/${this.$route.params.id}`);
        })
        .catch(() => {
          this.snackMessage =
            "質問の選択肢追加に失敗しました。時間をおいて再度お試しください。";
          this.snackbar = true;
        });
    },
    updateQuestionItems() {
      const payload = {
        surveyId: this.surveyId,
        questionId: this.question.id,
        addList: this.editingQuestion.selectionItems
          .filter((item) => item.id === undefined)
          .filter((item) => item.itemTitle !== "")
          .map((item) => item.itemTitle),
        editList: this.editingQuestion.selectionItems
          .filter((item) => item.id !== undefined)
          .filter((item) => item.itemTitle !== "")
          .map((item) => {
            const result = new Object();
            result.title = item.itemTitle;
            result.itemId = item.id;
            return result;
          }),
        deleteList: this.deleteList,
      };
      QuestionItemRepository.bulk(payload)
        .then(() => {
          this.closeDialog(true);
        })
        .catch(() => {
          this.snackMessage =
            "質問の選択肢更新に失敗しました。時間をおいて再度お試しください。";
          this.snackbar = true;
        });
    },
    deleteOneItem(i) {
      const itemToBeDeleted = this.editingQuestion.selectionItems[i];
      if (itemToBeDeleted.id !== undefined) {
        this.deleteList.push(itemToBeDeleted.id);
      }
      this.editingQuestion.selectionItems.splice(i, 1);
    },
    addOneItem() {
      if (this.editingQuestion.selectionItems === undefined) {
        this.$set(this.editingQuestion, "selectionItems", []);
      }
      this.editingQuestion.selectionItems.push({ itemTitle: "" });
    },
    updateErrorMessages(target) {
      if (target == "all" || target == "questionTitle") {
        if (
          this.editingQuestion.questionTitle === undefined ||
          this.editingQuestion.questionTitle.length <= 0
        ) {
          this.errors.questionTitle = "質問文は必須です";
        } else {
          this.errors.questionTitle = "";
        }
      }

      switch (this.questionType) {
        case "text_question":
          if (target == "all" || target == "maxLength") {
            if (
              this.editingQuestion.maxLength === undefined ||
              this.editingQuestion.maxLength <= 0
            ) {
              this.errors.maxLength = "最大文字数は必須です";
            } else {
              this.errors.maxLength = "";
            }
          }
          break;
        case "number_question":
          if (target == "all" || target == "minNumber") {
            if (
              this.editingQuestion.minNumber === undefined ||
              this.editingQuestion.minNumber < 0
            ) {
              this.errors.minNumber = "最小数値は必須です";
            } else {
              this.errors.minNumber = "";
            }
          }

          if (target == "all" || target == "maxNumber") {
            if (
              this.editingQuestion.maxNumber === undefined ||
              this.editingQuestion.maxNumber <= 0
            ) {
              this.errors.maxNumber = "最大数値は必須です";
            } else {
              this.errors.maxNumber = "";
            }
          }

          if (target == "all" || target == "maxNumber") {
            if (
              this.editingQuestion.maxNumber !== undefined &&
              this.editingQuestion.minNumber !== undefined &&
              this.editingQuestion.maxNumber <= this.editingQuestion.minNumber
            ) {
              this.errors.maxNumber =
                "最大数値は最小数値より大きくしてください";
            } else {
              this.errors.maxNumber = "";
            }
          }
          break;
        case "selection_question":
          if (target == "all" || target == "selectionItems") {
            if (this.editingQuestion.selectionItems !== undefined) {
              const validItemNum = this.editingQuestion.selectionItems.filter(
                (item) => item.itemTitle.length > 0
              ).length;
              if (validItemNum < 2) {
                this.errors.selectionItems = "選択肢は2つ以上入力してください";
              } else {
                this.errors.selectionItems = "";
              }
            } else {
              this.errors.selectionItems = "選択肢は2つ以上入力してください";
            }
          }
          break;
      }
    },
  },
};

function isValidQuestion() {
  switch (this.questionType) {
    case "text_question":
      return (
        this.editingQuestion &&
        this.editingQuestion.questionTitle !== undefined &&
        this.editingQuestion.questionTitle.length > 0 &&
        this.editingQuestion.maxLength > 0
      );
    case "number_question":
      return (
        this.editingQuestion &&
        this.editingQuestion.questionTitle !== undefined &&
        this.editingQuestion.questionTitle.length > 0 &&
        this.editingQuestion.minNumber >= 0 &&
        this.editingQuestion.maxNumber > 0 &&
        this.editingQuestion.maxNumber > this.editingQuestion.minNumber
      );
    case "selection_question":
      return (
        this.editingQuestion &&
        this.editingQuestion.questionTitle !== undefined &&
        this.editingQuestion.questionTitle.length > 0 &&
        this.editingQuestion.selectionItems !== undefined &&
        this.editingQuestion.selectionItems.filter(
          (item) => item.itemTitle.length > 0
        ).length >= 2
      );
  }

  return false;
}
</script>

<style scoped>
.dialog-background {
  background-color: #f6f6f6;
}
.section-title {
  color: #042a2b;
  font-size: 16px;
}
.custom-error {
  color: #db2d16 !important;
  padding-left: 12px;
  font-size: 12px;
}
</style>
