import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueScrollTo from "./plugins/vue-scrollto";
import router from "./router";
import VueAnalytics from "./plugins/vue-analytics";
import VueHtmlToPaper from "./plugins/vue-html-to-paper";

import store from "@/store";
import Vuelidate from "vuelidate";
import VueClipboard from "vue-clipboard2";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(VueClipboard);
Vue.use(VueMeta);

new Vue({
  router,
  vuetify,
  VueScrollTo,
  VueAnalytics,
  VueHtmlToPaper,
  store,
  render: (h) => h(App),
}).$mount("#app");
