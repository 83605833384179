<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="dialog-background">
      <v-toolbar dark color="primary" class="px-2">
        <v-btn icon dark @click="closeDialog(false)">
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-0">{{ naviTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="actionType === 'edit'">
          <v-toolbar-items>
            <v-icon @click="deleteSurvey()">mdi-trash-can</v-icon>
          </v-toolbar-items>
        </template>
      </v-toolbar>
      <v-col cols="12">
        <h2 class="section-title mt-2 mb-3">アンケートのタイトル</h2>
        <v-text-field
          v-model="editingSurvey.title"
          placeholder="アンケートのタイトルを入力してください"
          outlined
          background-color="white"
        ></v-text-field>

        <h2 class="section-title mb-3">公開設定</h2>
        <v-switch
          v-model="editingSurvey.isActive"
          inset
          :label="`${editingSurvey.isActive ? '公開中です' : '非公開中です'}`"
        ></v-switch>
      </v-col>
    </v-card>

    <v-btn
      fixed
      bottom
      right
      left
      color="primary"
      height="45px"
      max-width="450px"
      width="90%"
      class="mx-auto"
      @click="onSaveSurvey"
    >
      保存する
    </v-btn>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          本当にこのアンケートを削除してよろしいですか？
        </v-card-title>
        <v-card-text>
          全ての質問と回答も削除されます。取り消しはできません。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">
            キャンセル
          </v-btn>
          <v-btn color="primary" text @click="executeDeleteSurvey">
            確認して実行
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "../../../api/repositoryFactory";
const SurveyRepository = RepositoryFactory.get("surveys");

export default {
  name: "SurveyEditDialog",
  props: {
    dialog: {
      type: Boolean,
    },
    actionType: {
      type: String,
      require: true,
      default: "add", // 'add' or 'edit'
    },
    survey: {
      type: Object,
      require: false,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      editingSurvey: { ...this.survey },
      snackbar: false,
      snackMessage: "",
      deleteDialog: false,
    };
  },
  computed: {
    naviTitle() {
      const action = this.actionType === "add" ? "追加" : "編集";
      return "アンケートの" + action;
    },
  },

  methods: {
    closeDialog(needToRefresh) {
      this.$emit("close", needToRefresh);
    },
    onSaveSurvey() {
      if (this.editingSurvey.title.length > 0) {
        if (this.actionType == "edit") {
          this.$ga.event("update-survey");
          this.updateCurrentSurvey();
        }
      }
    },
    updateCurrentSurvey() {
      const payload = {
        surveyId: this.survey.id,
        isActive: this.editingSurvey.isActive,
        title: this.editingSurvey.title,
      };
      SurveyRepository.edit(payload)
        .then(() => {
          this.closeDialog(true);
        })
        .catch(() => {
          this.snackMessage =
            "アンケートの更新に失敗しました。時間をおいて再度お試しください。";
          this.snackbar = true;
        });
    },
    executeDeleteSurvey() {
      this.$ga.event("delete-survey");
      this.deleteDialog = false;
      const payload = {
        surveyId: this.survey.id,
      };
      SurveyRepository.delete(payload)
        .then(() => {
          this.closeDialog(true);
          this.$router.replace("/users");
        })
        .catch(() => {
          this.snackMessage =
            "アンケートの削除に失敗しました。時間をおいて再度お試しください。";
          this.snackbar = true;
        });
    },
    deleteSurvey() {
      this.deleteDialog = true;
    },
  },
};
</script>

<style scoped>
.dialog-background {
  background-color: #f6f6f6;
}
.section-title {
  color: #042a2b;
  font-size: 16px;
}
.custom-error {
  color: #db2d16 !important;
  padding-left: 12px;
  font-size: 12px;
}
</style>
