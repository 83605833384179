<template>
  <v-container>
    <v-progress-linear :value="currentProgress"></v-progress-linear>
    <v-row class="d-flex answer_top mt-2">
      <v-col cols="12">
        <h1 class="question-title">{{ question.questionTitle }}</h1>
        <template v-if="question.type === 'text_question'">
          <div class="mt-4">
            <v-textarea
              v-model="answerText"
              placeholder="回答を入力してください"
              outlined
              rows="3"
              :counter="question.maxLength"
              background-color="white"
              :error-messages="errors.answerText"
              @input="updateErrorMessages('answerText')"
              @blur="updateErrorMessages('answerText')"
            ></v-textarea>
          </div>
        </template>
        <template v-else-if="question.type === 'number_question'">
          <div class="mt-12 answer-input-container">
            <v-slider
              v-model="answerNumber"
              thumb-color="primary"
              thumb-label="always"
              :max="question.maxNumber"
              :min="question.minNumber"
              :error-messages="errors.answerNumber"
              @input="updateErrorMessages('answerNumber')"
              @blur="updateErrorMessages('answerNumber')"
              hide-details="auto"
            ></v-slider>
          </div>
        </template>
        <template v-else-if="question.type === 'selection_question'">
          <div
            v-if="question.isMultiple"
            class="mt-4 answer-input-container py-1"
          >
            <template v-for="item in question.selectionItems">
              <v-col cols="12" sm="4" :key="item.id" class="py-0">
                <v-checkbox
                  v-model="itemIds"
                  :label="item.itemTitle"
                  color="primary"
                  :value="item.id"
                  hide-details
                  class="mt-2"
                  @change="updateErrorMessages('selectionItems')"
                ></v-checkbox>
              </v-col>
            </template>
            <p class="mt-2 custom-error">
              {{ errors.selectionItems }}
            </p>
          </div>
          <div v-else class="mt-4 answer-input-container pa-3">
            <v-radio-group
              v-model="itemId"
              mandatory
              column
              hide-details
              class="mt-0"
            >
              <template v-for="item in question.selectionItems">
                <v-radio
                  :key="item.id"
                  :label="item.itemTitle"
                  color="primary"
                  :value="item.id"
                ></v-radio>
              </template>
            </v-radio-group>
          </div>
        </template>
      </v-col>

      <v-btn
        v-if="!question.isRequired"
        text
        fixed
        bottom
        right
        left
        class="mx-auto mb-12"
        color="primary"
        width="90%"
        height="45px"
        max-width="450px"
        @click="skipAnswer"
      >
        スキップ
      </v-btn>

      <v-btn
        fixed
        bottom
        right
        left
        class="mx-auto"
        color="primary"
        width="90%"
        height="45px"
        max-width="450px"
        @click="onClickAnswer"
      >
        保存して次へ
      </v-btn>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "CollectAnswer",
  components: {},
  props: {
    question: {
      type: Object,
      require: true,
      default() {
        return {};
      },
    },
    currentProgress: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  data() {
    return {
      snackbar: false,
      snackMessage: "",
      answerText: "",
      answerNumber: 0,
      itemIds: [],
      itemId: "",
      errors: {
        answerText: "",
        answerNumber: "",
        selectionItems: "",
      },
    };
  },
  watch: {},
  computed: {},
  validations: {},
  methods: {
    onClickAnswer() {
      this.updateErrorMessages("all");
      if (this.validateInputs()) {
        this.$emit("goToNext", this.findAnswer());
        this.clearAnswer();
      }
    },
    validateInputs() {
      switch (this.question.type) {
        case "text_question":
          return (
            this.answerText.length > 0 &&
            this.answerText.length <= this.question.maxLength
          );
        case "number_question":
          return true;
        case "selection_question":
          if (this.question.isMultiple) {
            return this.itemIds.length > 0;
          } else {
            return true;
          }
      }
    },
    findAnswer() {
      switch (this.question.type) {
        case "text_question":
          return this.answerText;
        case "number_question":
          return this.answerNumber;
        case "selection_question":
          if (this.question.isMultiple) {
            return this.itemIds;
          } else {
            return this.itemId;
          }
      }
    },
    clearAnswer() {
      this.answerText = "";
      this.answerNumber = 0;
      this.itemIds = [];
      this.itemId = "";
    },
    updateErrorMessages(target) {
      switch (this.question.type) {
        case "text_question":
          if (target == "all" || target == "answerText") {
            if (
              this.answerText.length <= 0 ||
              this.answerText.length > this.question.maxLength
            ) {
              this.errors.answerText = "回答は必須です";
            } else {
              this.errors.answerText = "";
            }
          }
          break;
        case "number_question":
          break;
        case "selection_question":
          if (target == "all" || target == "selectionItems") {
            if (this.question.isMultiple) {
              if (this.itemIds.length === 0) {
                this.errors.selectionItems = "回答は必須です";
              } else {
                this.errors.selectionItems = "";
              }
            } else {
              this.errors.selectionItems = "";
            }
          }
          break;
      }
    },
    skipAnswer() {
      this.$emit("goToNext");
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.question-title {
  color: #042a2b;
  font-size: 16px;
}
.answer-input-container {
  background: white;
  border-radius: 5px;
}
.custom-error {
  color: #db2d16 !important;
  padding-left: 12px;
  font-size: 12px;
}
</style>
