<template>
  <v-row class="d-flex">
    <v-col cols="12">
      <v-card elevation="2">
        <v-card-title class="pa-2 pb-0 my-0 mx-0">
          <v-row class="question-title my-0 mx-0">
            <v-icon color="primary">
              <template v-if="question_type === 'text_question'">
                mdi-format-text-variant-outline
              </template>
              <template v-else-if="question_type === 'number_question'">
                mdi-numeric
              </template>
              <template v-else-if="question_type === 'selection_question'">
                mdi-format-list-checkbox
              </template>
            </v-icon>
            <div class="ml-1">
              {{ title }}
            </div>
          </v-row>
        </v-card-title>
        <v-card-text class="pa-2 pt-0">
          <v-row class="my-0 mx-0">
            {{ desc }}
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "QuestionType",
  props: ["question_type"],
  components: {},
  data: () => ({}),
  computed: {
    title() {
      switch (this.question_type) {
        case "text_question":
          return "文章方式";
        case "number_question":
          return "数値選択";
        case "selection_question":
          return "選択方式";
      }
      return "";
    },
    desc() {
      switch (this.question_type) {
        case "text_question":
          return "回答者に自由な文章を入力してもらいます。文字数の制限等もできます。";
        case "number_question":
          return "回答者に選択肢に選択してもらいます。複数選択・単数選択のパターンがあります。";
        case "selection_question":
          return "回答者に数値を選択してもらいます。最大値と最小値を指定して、選択してもらいます。";
      }
      return "";
    },
  },
  validations: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.question-title {
  color: #042a2b;
  font-size: 12px;
  font-weight: bold;
}
</style>
