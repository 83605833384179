<template>
  <v-container>
    <v-row class="d-flex questions-container">
      <template v-if="survey.isActive && survey.questions.length > 0">
        <v-col cols="12">
          <h1 class="share-survey-title">{{ survey.title }}</h1>
        </v-col>
        <v-col cols="12 pb-2">
          <h2 class="share-survey-sub-title pb-0">共有リンク</h2>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-card elevation="2">
            <v-card-title>
              <router-link :to="link" class="survey-link" target="_blank">
                https://quick-survey.app{{ link }}
              </router-link>
            </v-card-title>
            <v-card-subtitle class="pb-0">
              このURLをアンケートに答えていただきたい<br />
              人達に共有しましょう！<br />
              タップするとコピーされます。
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="copyLink" color="primary" text>
                <v-icon>mdi-content-copy</v-icon>
                <span>コピーする</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12 pb-2">
          <h2 class="share-survey-sub-title pb-0">QRコード</h2>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-card elevation="2">
            <vue-qrcode
              :value="fullLink"
              :options="qrOptions"
              tag="img"
              class="qr-code-img"
            ></vue-qrcode>
          </v-card>
        </v-col>
      </template>
      <template v-else>
        <survey-not-ready></survey-not-ready>
      </template>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import SurveyNotReady from "./SurveyNotReady.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "SurveyDetailShare",
  props: ["survey"],
  components: { SurveyNotReady, VueQrcode },
  data: () => ({
    snackbar: false,
    snackMessage: "",
    qrOptions: {
      errorCorrectionLevel: "M",
      maskPattern: 0,
      margin: 10,
      scale: 2,
      width: 250,
      color: {
        dark: "#000000FF",
        light: "#FFFFFFFF",
      },
    },
  }),
  computed: {
    link() {
      return `/p/${this.survey.id}`;
    },
    fullLink() {
      return "https://quick-survey.app" + this.link;
    },
  },
  validations: {},
  methods: {
    copyLink() {
      this.$ga.event("copy-url");
      const mySelf = this;
      const link = this.fullLink;
      this.$copyText(link).then(
        function() {
          mySelf.snackMessage = "コピーしました";
          mySelf.snackbar = true;
        },
        function() {}
      );
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.share-survey-title {
  color: #042a2b;
  font-size: 16px;
}
.share-survey-sub-title {
  font-size: 12px;
}
.survey-link {
  color: #ee6352;
  text-decoration: none;
}
.qr-code-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
