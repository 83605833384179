<template>
  <v-container>
    <v-col class="pl-0" cols="12">
      <h1 class="survey-title">
        {{ survey.title }}
      </h1>
    </v-col>
    <v-row
      class="d-flex empty-question-container"
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <h1 class="empty-question-title">
          アンケートに質問が<br />追加されていません。
        </h1>

        <v-img
          alt="質問なし画像"
          class="shrink .empty-image mt-4 mx-auto"
          contain
          max-width="250"
          src="../../../assets/question-empty-image.png"
        />
        <p class="empty-description mt-4">
          「質問を自分で追加する」ボタンから<br />最初の質問を追加しましょう！<br />
        </p>
        <p class="empty-description mt-4">
          「テンプレート」から質問を追加することもできます。
        </p>
      </v-col>
    </v-row>
    <add-question-floating-buttons
      @addQuestion="addQuestion"
      @addQuestionFromTemplate="addQuestionFromTemplate"
    ></add-question-floating-buttons>
  </v-container>
</template>

<script>
import AddQuestionFloatingButtons from "./AddQuestionFloatingButtons.vue";

export default {
  name: "SurveyDetailEmptyQuestion",
  props: ["survey"],
  components: { AddQuestionFloatingButtons },
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {
    addQuestion() {
      this.$emit("addQuestion");
    },
    addQuestionFromTemplate() {
      this.$emit("addQuestionFromTemplate");
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.survey-title {
  color: #042a2b;
  font-size: 16px;
}
.empty-question-container {
  text-align: center;
  margin: auto;
  margin-bottom: 140px;
}
.empty-question-title {
  color: #042a2b;
  font-size: 16px;
}
.empty-image {
  margin: 0 auto;
  text-align: center;
}
.empty-description {
  color: #042a2b;
  font-size: 12px;
}
</style>
