<template>
  <div class="user_detail_container">
    <v-container>
      <v-row class="d-flex">
        <v-col v-if="!isPaidUser" cols="12" md="6">
          <h2 class="section-title mt-2 mb-3">無料プラン</h2>
          <div class="price-item-area-white">
            <div class="mt-8">
              <p class="price-title-primary">
                <span class="bigger-amount-title">0</span>円/月
              </p>
              <p class="price-text-color-gray">初期費用無料</p>
            </div>
            <hr class="price-border-color-gray" />
            <div class="mt-4 mr-4 ml-4 price-description-area">
              <p class="price-text-color-gray">
                現在ご利用いただいている無料のプランです。<br />クイックアンケートを少し試してみたい方向けです。
              </p>
            </div>
            <hr class="price-border-color-gray" />
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>アンケート数</td>
                    <td>1つまで</td>
                  </tr>
                  <tr>
                    <td>テンプレート</td>
                    <td>一部、利用可能</td>
                  </tr>
                  <tr>
                    <td>印刷機能</td>
                    <td>お使いいただけません</td>
                  </tr>
                  <tr>
                    <td>サポート体制</td>
                    <td>標準サポート</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <hr class="price-border-color-gray" />
            <div class="mt-4 mb-8">
              <v-btn
                @click="prepareForCheck"
                elevation="3"
                large
                color="primary"
                class="register-button"
                disabled
              >
                <v-icon>mdi-check</v-icon>
                <span color="white">現在のプランです</span>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <h2 class="section-title mt-2 mb-3">基本プラン</h2>
          <div class="price-item-area-white">
            <div class="mt-8">
              <p class="price-title-primary">
                <span class="bigger-amount-title">1,980</span>円/月
              </p>
              <p class="price-text-color-gray">初期費用無料</p>
            </div>
            <hr class="price-border-color-gray" />
            <div class="mt-4 mr-4 ml-4 price-description-area">
              <p class="price-text-color-gray">
                クイックアンケートの全ての機能が使える基本プラン。<br />類似サービスと比べても格安でご利用いただけます。
              </p>
            </div>
            <hr class="price-border-color-gray" />
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>アンケート数</td>
                    <td><strong>✅ 無制限</strong></td>
                  </tr>
                  <tr>
                    <td>テンプレート</td>
                    <td><strong>✅ 全て利用可能</strong></td>
                  </tr>
                  <tr>
                    <td>印刷機能</td>
                    <td><strong>✅ ご利用可能</strong></td>
                  </tr>
                  <tr>
                    <td>サポート体制</td>
                    <td><strong>✅ 優先サポート</strong></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <hr class="price-border-color-gray" />
            <div class="mt-4 mb-8">
              
            </div>
          </div>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            閉じる
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
const PaymentRepository = RepositoryFactory.get("payment");
const UserRepository = RepositoryFactory.get("users");

export default {
  name: "Checkout",
  props: [],
  components: {},
  data: () => ({
    user: {},
    portalUrl: null,
    isActive: false,
    basicPriceId: null,
    publishableKey: null,
    snackbar: false,
    snackMessage: "",
  }),
  computed: {
    isPaidUser() {
      return this.$store.getters["user/isPaidPlan"];
    },
  },
  methods: {
    prepareForCheck() {
      const payload = { priceId: this.basicPriceId };
      PaymentRepository.createCheckoutSession(payload)
        .then((res) => {
          this.startCheckout(res.data.body.sessionId);
        })
        .catch(() => {
          this.isActive = false;
          this.snackbar = true;
          this.snackMessage =
            "エラーが発生しました。時間を置いて再度お試しください";
        });
    },
    goToPortal() {
    },
    startCheckout(sessionId) {
      const stripe = window.Stripe(this.publishableKey);
      stripe
        .redirectToCheckout({
          sessionId: sessionId,
        })
        .then((result) => {
          if (result.error) {
            this.isActive = false;
            this.snackbar = true;
            this.snackMessage = result.error.message;
            return;
          }
        });
    },
    setupStripe() {
      PaymentRepository.setupStripe()
        .then((res) => {
          this.isActive = true;
          this.basicPriceId = res.data.body.basicPrice;
          this.publishableKey = res.data.body.publishableKey;
        })
        .catch(() => {
          this.isActive = false;
          this.snackbar = true;
          this.snackMessage =
            "エラーが発生しました。時間を置いて再度お試しください";
        });
    },
    setupBillingBoard() {
      PaymentRepository.getPortal()
        .then((res) => {
          this.portalUrl = res.data.body.url;
          this.isActive = true;
        })
        .catch(() => {
          this.isActive = false;
          this.snackbar = true;
          this.snackMessage =
            "エラーが発生しました。時間を置いて再度お試しください";
        });
    },
    fetchUserInfo() {
      UserRepository.me()
        .then((res) => {
          this.user = res.data.body;
          if (!this.isPaidUser) {
            this.setupStripe();
          } else {
            this.setupBillingBoard();
          }
        })
        .catch(() => {});
    },
  },
  async mounted() {
    this.fetchUserInfo();
  },
};
</script>

<style scoped>
.user_detail_container {
  background-color: #f6f6f6;
  height: 100%;
}
.section-title {
  color: #042a2b;
  font-size: 16px;
}
.price-item-area-white {
  text-align: center;
  background: white;
  border: #e7eaee solid 1px;
  border-radius: 5px;
}
.price-title-primary {
  color: #ee6352;
  font-size: 28px;
}
.bigger-amount-title {
  font-size: 48px;
}
.price-text-color-gray {
  color: #7e8085;
}
.price-border-color-gray {
  background-color: #e7eaee;
  border: none;
  height: 1px;
}
</style>
