<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <h1>ログイン</h1>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-form>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="メールアドレス"
            type="email"
            autocomplete="username"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="current-password"
            label="パスワード"
            counter
            @click:append="showPassword = !showPassword"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>

          <v-btn
            class="mt-4 primary sign-in-button"
            width="100%"
            height="60px"
            @click="signin"
          >
            ログインをする
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
    <hr class="mt-6" />
    <v-row class="mt-1">
      <v-col cols="12">
        <v-row align="center" justify="center" class="mr-0 ml-0">
          <p>
            アカウントをお持ちでない方は、
            <router-link to="signup">こちらから無料で会員登録</router-link>
          </p>
        </v-row>
        <v-row class="mr-0 ml-0">
          <p>
            パスワードを忘れた場合は、
            <a class="password-link" @click="forgetPassword">こちら</a>
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="passwordDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="pb-0">
          <span class="headline">登録したメールアドレスの入力</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="forgotEmail"
            :error-messages="forgotEmailErrors"
            placeholder="登録したメールアドレスの入力"
            required
            email
            type="email"
            @input="$v.forgotEmail.$touch()"
            @blur="$v.forgotEmail.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="passwordDialog = false">
            閉じる
          </v-btn>
          <v-btn color="primary" @click="sendResetEmail">送信</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { RepositoryFactory } from "../api/repositoryFactory";
const UserRepository = RepositoryFactory.get("users");
const PublicRepository = RepositoryFactory.get("public");

export default {
  name: "Signin",
  components: {},
  metaInfo: {
    title: "ログイン | クイックアンケート",
  },
  data: () => ({
    loading: false,
    password: "",
    email: "",
    showPassword: false,
    snackbar: false,
    errorMessage: "ログインに失敗しました。",
    timeout: 2000,
    passwordDialog: false,
    forgotEmail: "",
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push("正しいメールアドレスを入力してください");
      !this.$v.email.required && errors.push("メールアドレスは必須です");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("パスワードは8文字以上で入力してください");
      !this.$v.password.required && errors.push("パスワードは必須です");
      return errors;
    },
    forgotEmailErrors() {
      const errors = [];
      if (!this.$v.forgotEmail.$dirty) return errors;
      !this.$v.forgotEmail.email &&
        errors.push("正しいメールアドレスを入力してください");
      !this.$v.forgotEmail.required && errors.push("メールアドレスは必須です");
      return errors;
    },
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
    forgotEmail: { required, email },
  },
  methods: {
    signin() {
      this.$v.email.$touch();
      this.$v.password.$touch();
      if (!this.$v.email.$invalid && !this.$v.password.$invalid) {
        this.loginUser(this.email, this.password);
      }
    },
    loginUser(email, password) {
      this.loading = true;
      const payload = {
        username: email,
        password: password,
      };
      UserRepository.signin(payload)
        .then((response) => {
          this.loading = false;
          this.$store.commit("user/create", response.data.body);
          this.$router.push("/users");
        })
        .catch(() => {
          this.loading = false;
          this.snackbar = true;
        });
    },
    forgetPassword() {
      this.passwordDialog = true;
    },
    sendResetEmail() {
      this.passwordDialog = false;
      this.$v.forgotEmail.$touch();
      if (!this.$v.forgotEmail.$invalid) {
        const payload = { email: this.forgotEmail };
        PublicRepository.resetPassword(payload)
          .then(() => {
            this.snackbar = true;
            this.errorMessage =
              "メールボックスを確認して、パスワード変更を行ってください";
            this.forgotEmail = "";
            this.$v.forgotEmail.$reset();
          })
          .catch(() => {
            this.snackbar = true;
            this.errorMessage =
              "処理に失敗しました。時間をおいて再度お試しください";
            this.forgotEmail = "";
            this.$v.forgotEmail.$reset();
          });
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 400px;
}
.password-link {
  text-decoration: underline;
}
.sign-in-button {
  font-size: 16px;
}
</style>
