<template>
  <div class="footer-area">
    <v-container fill-height>
      <!-- Hide for xs, sm -->
      <v-row
        class="footer-row d-none d-md-flex"
        align="center"
        justify="center"
      >
        <v-col cols="12" sm="6">
          <div class="copy-right-area">
            <p class="mb-0">
              © {{ currentYear }} クイックアンケート All Rights Reserved.
            </p>
          </div>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="footer-info-area">
            <v-btn href="https://blog.quick-survey.app/" text target="_blank">
              <span class="">ブログ</span>
            </v-btn>
            <v-btn to="/term" text>
              <span class="">利用規約</span>
            </v-btn>
            <v-btn to="/privacy" text>
              <span class="">プライバシーポリシー</span>
            </v-btn>
            <v-btn
              href="https://mail.google.com/mail/?view=cm&fs=1&to=kazuya@quick-survey.app&su=【クイックアンケート】お問い合わせ"
              text
              target="_blank"
            >
              <span class="">お問い合わせ</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- Display for xs, sm -->
      <v-row
        class="footer-row-mobile d-flex d-md-none"
        align="center"
        justify="center"
        no-gutters
      >
        <v-col cols="12">
          <v-btn href="https://blog.quick-survey.app/" text target="_blank">
            <span class="">ブログ</span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn to="/term" text>
            <span class="">利用規約</span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn to="/privacy" text>
            <span class="">プライバシーポリシー</span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            href="https://mail.google.com/mail/?view=cm&fs=1&to=kazuya@quick-survey.app&su=【クイックアンケート】お問い合わせ"
            text
            target="_blank"
          >
            <span class="">お問い合わせ</span>
          </v-btn>
        </v-col>

        <v-col cols="12" class="mt-4">
          <div class="copy-right-area-mobile">
            <p class="mb-0">
              © {{ currentYear }} クイックアンケート All Rights Reserved.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    currentYear() {
      const d = new Date();
      return d.getFullYear();
    },
  },
};
</script>

<style scoped>
.footer-area {
  height: 180px;
}
.footer-info-area {
  text-align: right;
}
.footer-row-mobile {
  text-align: center;
}
.copy-right-area-mobile p {
  font-size: 10px;
}
</style>
