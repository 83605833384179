<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title class="pb-0">
        <span class="headline">メールアドレスの変更</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          placeholder="新しいメールアドレスを入力してください"
          type="email"
          autocomplete="username"
          required
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          閉じる
        </v-btn>
        <v-btn color="primary" @click="saveClicked">確認メールを送信</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      email: "",
    };
  },
  watch: {},
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push("正しいメールアドレスを入力してください");
      !this.$v.email.required && errors.push("メールアドレスは必須です");
      return errors;
    },
  },
  methods: {
    closeDialog() {
      this.email = "";
      this.$emit("close");
    },
    saveClicked() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("save", this.email);
        this.email = "";
      }
    },
  },
  validations: {
    email: { required, email },
  },
};
</script>

<style scoped>
.headline {
  color: #042a2b;
  font-size: 16px !important;
}
</style>
