<template>
  <div class="confirm_email_container">
    <v-container>
      <v-row class="d-flex answer_top mx-auto">
        <v-col cols="12">
          <h1 class="title">メールアドレスの確認</h1>
          <v-form>
            <v-text-field
              v-model="password1"
              :error-messages="password1Errors"
              label="新しいパスワード"
              :type="showPassword1 ? 'text' : 'password'"
              :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
              required
              @click:append="showPassword1 = !showPassword1"
              @input="$v.password1.$touch()"
              @blur="$v.password1.$touch()"
            ></v-text-field>

            <v-text-field
              v-model="password2"
              :error-messages="password2Errors"
              :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword2 ? 'text' : 'password'"
              label="新しいパスワードの確認"
              @click:append="showPassword2 = !showPassword2"
              @input="$v.password2.$touch()"
              @blur="$v.password2.$touch()"
            ></v-text-field>

            <v-btn
              class="mt-4 primary"
              width="100%"
              height="60px"
              @click="tryToChangePassword"
            >
              パスワードを変更する
            </v-btn>
          </v-form>
        </v-col>

        <v-btn
          fixed
          bottom
          right
          left
          text
          class="mx-auto"
          color="primary"
          width="90%"
          height="45px"
          max-width="450px"
          @click="$router.replace('/')"
        >
          ホームへ戻る
        </v-btn>
      </v-row>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            閉じる
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
const PublicRepository = RepositoryFactory.get("public");

export default {
  name: "ResetPassword",
  components: {},
  data: () => ({
    snackbar: false,
    snackMessage: "",
    statusMessage: "メールアドレスの確認に成功しました！",
    password1: "",
    password2: "",
    showPassword1: false,
    showPassword2: false,
  }),
  computed: {
    password1Errors() {
      const errors = [];
      if (!this.$v.password1.$dirty) return errors;
      !this.$v.password1.minLength &&
        errors.push("パスワードは8文字以上で入力してください");
      !this.$v.password1.required && errors.push("パスワードは必須です");
      return errors;
    },
    password2Errors() {
      const errors = [];
      if (!this.$v.password2.$dirty) return errors;
      !this.$v.password2.minLength &&
        errors.push("パスワードは8文字以上で入力してください");
      !this.$v.password2.required && errors.push("パスワードは必須です");
      !this.$v.password2.sameAsPassword &&
        errors.push("パスワードが異なります");
      return errors;
    },
  },
  validations: {
    password1: { required, minLength: minLength(8) },
    password2: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs("password1"),
    },
  },
  methods: {
    displayError() {
      this.snackbar = true;
      this.snackMessage = "不正なページです";
      this.statusMessage = "";
    },
    displaySuccess() {
      this.snackbar = true;
      this.snackMessage = "メールアドレスの確認に成功しました";
    },
    displayPasswordChange() {
      this.snackbar = true;
      this.snackMessage =
        "メールアドレスの変更に成功しました。ログアウトし、再度ログインをしてください。";
    },
    tryToChangePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.executeToChangePassword();
      }
    },
    executeToChangePassword() {
      const token = this.$route.query.token;
      const payload = { token: token, newPassword: this.password2 };

      PublicRepository.changePassword(payload)
        .then(() => {
          this.snackbar = true;
          this.snackMessage = "パスワードの変更に成功しました。";
          this.password1 = "";
          this.password2 = "";
          this.$v.$reset();
          this.goToHome();
        })
        .catch(() => {
          this.snackbar = true;
          this.snackMessage =
            "処理に失敗しました。時間をおいて再度お試しください";
          this.password1 = "";
          this.password2 = "";
          this.$v.$reset();
        });
    },
    async goToHome() {
      await sleep(2000);
      this.$router.replace("/signin");
    },
  },
  async mounted() {},
};
function sleep(msec) {
  return new Promise(function(resolve) {
    setTimeout(function() {
      resolve();
    }, msec);
  });
}
</script>

<style scoped>
.confirm_email_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.answer_top {
  max-width: 400px;
}
.title {
  color: #042a2b;
  font-size: 16px;
  text-align: center;
}
.description {
  color: #042a2b;
  font-size: 16px;
  text-align: center;
}
</style>
