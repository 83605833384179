import UserRepository from "./user/userRepository";
import SurveyRepository from "./survey/surveyRepository";
import QuestionRepository from "./question/questionRepository";
import QuestionItemRepository from "./question/questionItemRepository";
import PublicRepository from "./public/publicRepository";
import PaymentRepository from "./payment/paymentRepository";
import ResourceRepository from "./resource/resourceRepository";

const repositories = {
  users: UserRepository,
  surveys: SurveyRepository,
  questions: QuestionRepository,
  items: QuestionItemRepository,
  public: PublicRepository,
  payment: PaymentRepository,
  resource: ResourceRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
