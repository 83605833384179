<template>
  <v-container>
    <!-- For Desktop -->
    <v-row class="d-none d-sm-flex" justify="center" align="center">
      <v-col cols="6">
        <v-img
          alt="ヒーロー画像"
          class="shrink mr-2"
          contain
          src="../../assets/devices.png"
        />
      </v-col>
      <v-col cols="6">
        <h1 class="main-title">
          迅速にアンケートを作成<br />数クリックで準備完了！
        </h1>
        <p class="mt-2 sub-decription">
          テンプレート選択だけでアンケートを作成&配信できます<br />
          QRコード・SNS・メールでアンケートを集めましょう！
        </p>
        <v-btn
          @click="goToSignup('HeroDesktop')"
          elevation="3"
          large
          color="primary"
        >
          <span color="white">無料で会員登録する</span>
        </v-btn>
      </v-col>
    </v-row>
    <!-- For Mobile -->
    <v-row class="d-flex d-sm-none" align="center" justify="center">
      <v-col cols="12">
        <h1 class="main-title-mobile">
          迅速にアンケートを作成<br />数タップで準備完了！
        </h1>
        <p class="pa-2 sub-decription-mobile">
          テンプレート選択だけでアンケートを作成&配信<br />
          QRコード・SNS・メールでアンケートを収集！
        </p>
        <v-img
          alt="ヒーロー画像"
          class="shrink hero-image-mobile"
          contain
          src="../../assets/devices.png"
          max-width="80%"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Hero",
  methods: {
    async goToSignup(name) {
      this.$emit("goToSignup", name);
    },
  },
};
</script>

<style scoped>
.main-title {
  color: #042a2b;
}
.main-title-mobile {
  color: #042a2b;
  font-size: 18px;
  text-align: center;
}
.sub-decription {
  color: #7e8085;
}
.sub-decription-mobile {
  color: #7e8085;
  font-size: 12px;
  text-align: center;
}
.hero-image-mobile {
  margin: 0 auto;
}
</style>
