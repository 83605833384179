<template>
  <div class="select_quection_type_container">
    <v-col cols="12">
      <h1 class="question-type-title">質問方式を選択してください</h1>
      <question-type
        @click.native="goToCreateAnswer('text_question')"
        :question_type="'text_question'"
      ></question-type>
      <question-type
        @click.native="goToCreateAnswer('selection_question')"
        :question_type="'selection_question'"
      ></question-type>
      <question-type
        @click.native="goToCreateAnswer('number_question')"
        :question_type="'number_question'"
      ></question-type>

      <edit-question-dialog
        v-on:close="closeDialog()"
        :dialog="dialog"
        :questionType="selectedQuestionType"
        :actionType="'add'"
        :surveyId="String(this.$route.params.id)"
      ></edit-question-dialog>
    </v-col>
  </div>
</template>

<script>
import QuestionType from "../components/question_type/QuestionType.vue";
import EditQuestionDialog from "../components/question/EditQuestionDialog.vue";

export default {
  name: "SelectQuestionType",
  props: [],
  components: { QuestionType, EditQuestionDialog },
  data: () => ({
    dialog: false,
    selectedQuestionType: "",
  }),
  computed: {},
  validations: {},
  methods: {
    goToCreateAnswer(type) {
      this.selectedQuestionType = type;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.select_quection_type_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.question-type-title {
  color: #042a2b;
  font-size: 16px;
}
</style>
