<template>
  <v-row class="d-flex">
    <v-col cols="12">
      <v-card elevation="2">
        <v-card-title class="pa-2 question-title">
          質問文: {{ question.questionTitle }}
          <v-spacer></v-spacer>
          <v-icon v-if="!isTemplatePreview" color="primary">
            mdi-square-edit-outline
          </v-icon>
        </v-card-title>

        <div class="ma-2 mt-0 px-2 question-preview-container">
          <template v-if="question.type === 'selection_question'">
            <div class="selection-question-container my-2">
              <div v-for="item in question.selectionItems" :key="item.id">
                <v-icon v-if="question.isMultiple" color="#D8D8D8">
                  mdi-square-rounded-outline
                </v-icon>
                <v-icon v-if="!question.isMultiple" color="#D8D8D8">
                  mdi-checkbox-blank-circle-outline
                </v-icon>
                {{ item.itemTitle }}
              </div>
            </div>
          </template>
          <template v-if="question.type === 'number_question'">
            <div class="number-question-container">
              {{ question.minNumber }}
              <v-progress-linear
                value="100"
                color="primary"
                class="mx-2"
                rounded
              ></v-progress-linear>
              {{ question.maxNumber }}
            </div>
          </template>
        </div>

        <div class="pa-2 sub-info-container">
          <span class="sub-info-text">{{ subInfoText }}</span>
          <v-spacer></v-spacer>
          <p class="sub-info-label ma-0">
            <v-icon small color="white">
              <template v-if="question.type === 'text_question'">
                mdi-format-text-variant-outline
              </template>
              <template v-else-if="question.type === 'number_question'">
                mdi-numeric
              </template>
              <template v-else-if="question.type === 'selection_question'">
                mdi-format-list-checkbox
              </template>
            </v-icon>
            {{ typeName }}
          </p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "QuestionItem",
  props: ["question", "isTemplatePreview"],
  components: {},
  data: () => ({}),
  computed: {
    subInfoText() {
      switch (this.question.type) {
        case "text_question":
          return `最大文字数: ${this.question.maxLength}文字`;
        case "number_question":
          return "";
        case "selection_question":
          return this.question.isMultiple ? "複数選択" : "単数選択";
      }
      return "";
    },
    typeName() {
      switch (this.question.type) {
        case "text_question":
          return "文章方式";
        case "number_question":
          return "数値選択";
        case "selection_question":
          return "選択方式";
      }
      return "";
    },
  },
  validations: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.question-title {
  color: #042a2b;
  font-size: 12px;
}
.sub-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub-info-text {
  color: #042a2b;
  font-size: 10px;
}
.sub-info-label {
  background-color: #00b4d8;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
.question-preview-container {
  display: flex;
  border-radius: 5px;
  border: 1px solid #f6f6f6;
  min-height: 36px;
}
.number-question-container {
  display: flex;
  align-items: center;
  width: 100%;
  color: #042a2b;
}
.selection-question-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #042a2b;
}
</style>
