<template>
  <div class="survey_detail_container">
    <survey-detail-edit
      v-if="activeTab == 'edit' && survey !== undefined"
      :survey="survey"
      @refreshSurvey="refreshSurvey"
    ></survey-detail-edit>
    <survey-detail-share
      v-if="activeTab === 'share'"
      :survey="survey"
    ></survey-detail-share>
    <survey-detail-analytics
      v-if="activeTab == 'analytics'"
      :survey="survey"
    ></survey-detail-analytics>

    <v-bottom-navigation
      v-model="activeTab"
      @change="tabSelected"
      color="primary"
      grow
      fixed
    >
      <v-btn value="edit" class="disable-dbl-tap-zoom">
        <span>編集</span>
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>

      <v-btn value="share" class="disable-dbl-tap-zoom">
        <span>共有</span>
        <v-icon>mdi-export-variant</v-icon>
      </v-btn>

      <v-btn value="analytics" class="disable-dbl-tap-zoom">
        <span>分析</span>
        <v-icon>mdi-chart-bar</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
const SurveyRepository = RepositoryFactory.get("surveys");

import SurveyDetailAnalytics from "../components/survey_detail/analytics/SurveyDetailAnalytics.vue";
import SurveyDetailEdit from "../components/survey_detail/edit/SurveyDetailEdit.vue";
import SurveyDetailShare from "../components/survey_detail/share/SurveyDetailShare.vue";

export default {
  name: "SurveyDetail",
  props: [],
  components: { SurveyDetailShare, SurveyDetailAnalytics, SurveyDetailEdit },
  data: () => ({
    survey: {},
    activeTab: "edit", // edit, share, analytics
  }),
  computed: {},
  validations: {},
  methods: {
    fetchSurvey(id) {
      SurveyRepository.get(id)
        .then((res) => {
          this.survey = res.data.body.survey;
        })
        .catch(() => {});
    },
    refreshSurvey() {
      this.fetchSurvey(this.$route.params.id);
    },
    tabSelected() {
      this.addParamsToLocation({ tab: this.activeTab });
    },
    addParamsToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(params)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
              );
            })
            .join("&")
      );
    },
  },
  async mounted() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    }
    this.fetchSurvey(this.$route.params.id);
  },
};
</script>

<style scoped>
.survey_detail_container {
  background-color: #f6f6f6;
  height: 100%;
}
</style>
