<template>
  <v-container class="analytics-container">
    <v-row class="d-flex">
      <v-col cols="12">
        <h1 class="analytics-survey-title">{{ survey.title }}</h1>
      </v-col>
      <v-col cols="12">
        <v-card elevation="2">
          <div class="overview-container pa-4">
            <div class="count-container">
              <p class="count-title mb-0">アクセス数</p>
              <p class="count-number mb-0">{{ analytics.accessCount }}</p>
            </div>
            <div class="count-container">
              <p class="count-title mb-0">回答数</p>
              <p class="count-number mb-0">{{ analytics.answerCount }}</p>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <template v-for="question in analytics.questions">
          <div :key="question.id" class="my-2 each-question-title-area">
            <h2>{{ question.title }}</h2>
          </div>

          <template v-if="question.type === 'text_question'">
            <v-card :key="question.id + 't'" elevation="2">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">日付</th>
                    <th class="text-left">回答</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="answer in question.textAnswerContent.answers.slice(
                      0,
                      3
                    )"
                    :key="answer.id"
                  >
                    <td>{{ answer.createdAt }}</td>
                    <td>{{ answer.answer }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </template>
          <template v-else-if="question.type === 'number_question'">
            <v-card :key="question.id + 'n'" elevation="2">
              <div class="overview-container pa-4">
                <div class="count-container">
                  <p class="count-title mb-0">最小値</p>
                  <p class="count-number mb-0">
                    {{ question.numberAnswerContent.min }}
                  </p>
                </div>
                <div class="count-container">
                  <p class="count-title mb-0">平均値</p>
                  <p class="count-number mb-0">
                    {{ question.numberAnswerContent.average }}
                  </p>
                </div>
                <div class="count-container">
                  <p class="count-title mb-0">最大値</p>
                  <p class="count-number mb-0">
                    {{ question.numberAnswerContent.max }}
                  </p>
                </div>
              </div>
            </v-card>
          </template>
          <template v-else-if="question.type === 'selection_question'">
            <v-card :key="question.id + 's'" elevation="2">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">選択肢</th>
                    <th class="text-left">回答数</th>
                    <th class="text-left">割合</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="answer in question.selectionAnswerContent.items"
                    :key="answer.id"
                  >
                    <td>{{ answer.title }}</td>
                    <td>{{ answer.count }}</td>
                    <td>{{ answer.percent.toPrecision(3) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </template>

          <div
            :key="question.id + 'b'"
            class="mt-2 mb-6 each-question-bottom-area"
          >
            <p class="sub-info-label ma-0">
              <v-icon small color="white">
                <template v-if="question.type === 'text_question'">
                  mdi-format-text-variant-outline
                </template>
                <template v-else-if="question.type === 'number_question'">
                  mdi-numeric
                </template>
                <template v-else-if="question.type === 'selection_question'">
                  mdi-format-list-checkbox
                </template>
              </v-icon>
              {{ typeName(question.type) }}
            </p>
            <router-link
              :to="{ path: '/questions/' + question.id }"
              class="question-detail-link"
            >
              すべての回答を見る
            </router-link>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { RepositoryFactory } from "../../../api/repositoryFactory";
const SurveyRepository = RepositoryFactory.get("surveys");

export default {
  name: "SurveyDetailAnalytics",
  props: ["survey"],
  components: {},
  data() {
    return {
      analytics: {},
    };
  },
  computed: {},
  validations: {},
  methods: {
    fetchSurveyAnalytics(id) {
      SurveyRepository.analytics(id)
        .then((res) => {
          this.analytics = res.data.body;
        })
        .catch(() => {});
    },
    typeName(type) {
      switch (type) {
        case "text_question":
          return "文章方式";
        case "number_question":
          return "数値選択";
        case "selection_question":
          return "選択方式";
      }
      return "";
    },
  },
  async mounted() {
    this.fetchSurveyAnalytics(this.$route.params.id);
  },
};
</script>

<style scoped>
.analytics-container {
  margin-bottom: 60px;
}
.overview-container {
  display: flex;
  justify-content: space-evenly;
}
.count-container {
  width: 65px;
  text-align: center;
}
.count-title {
  color: #042a2b;
  font-size: 12px;
}
.count-number {
  color: #ee6352;
  font-size: 24px;
  font-weight: bold;
}
.each-question-title-area {
  display: flex;
}
.each-question-title-area h2 {
  color: #042a2b;
  font-size: 12px;
}
.sub-info-label {
  background-color: #00b4d8;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
.each-question-bottom-area {
  display: flex;
  justify-content: space-between;
}
.question-detail-link {
  color: #ee6352;
  text-decoration: none;
}
.analytics-survey-title {
  color: #042a2b;
  font-size: 16px;
}
</style>
