<template>
  <div class="confirm_email_container">
    <v-container>
      <v-row class="d-flex answer_top">
        <v-col cols="12">
          <h1 class="title mx-auto">メールアドレスの確認</h1>
          <v-img
            alt="メールアドレスの確認"
            class="shrink mt-6 mx-auto"
            contain
            size="200"
            max-width="200"
            src="../assets/confirm-email.png"
          />
          <p class="description mt-4">
            {{ statusMessage }}
          </p>
        </v-col>

        <v-btn
          fixed
          bottom
          right
          left
          class="mx-auto"
          color="primary"
          width="90%"
          height="45px"
          max-width="450px"
          @click="onGoBackToHome"
        >
          ホームへ戻る
        </v-btn>
      </v-row>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            閉じる
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
const PublicRepository = RepositoryFactory.get("public");

export default {
  name: "ConfirmSignupEmail",
  components: {},
  data: () => ({
    snackbar: false,
    snackMessage: "",
    statusMessage: "メールアドレスの確認に成功しました！",
  }),
  computed: {},
  validations: {},
  methods: {
    onGoBackToHome() {
      this.$router.replace("/");
    },
    displayError() {
      this.snackbar = true;
      this.snackMessage = "不正なページです";
      this.statusMessage = "";
    },
    displaySuccess() {
      this.snackbar = true;
      this.snackMessage = "メールアドレスの確認に成功しました";
    },
    displayPasswordChange() {
      this.snackbar = true;
      this.snackMessage =
        "メールアドレスの変更に成功しました。ログアウトし、再度ログインをしてください。";
    },
  },
  async mounted() {
    const token = this.$route.query.token;
    const type = this.$route.query.type || "";
    if (token !== undefined && token.length > 0) {
      PublicRepository.confirmEmail(token, type)
        .then(() => {
          if (type === "change") {
            this.displayPasswordChange();
          } else {
            this.displaySuccess();
          }
        })
        .catch(() => {
          this.displayError();
        });
    } else {
      this.displayError();
    }
  },
};
</script>

<style scoped>
.confirm_email_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.title {
  color: #042a2b;
  font-size: 16px;
  text-align: center;
}
.description {
  color: #042a2b;
  font-size: 16px;
  text-align: center;
}
</style>
