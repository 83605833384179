import repository from "../repository";

export default {
  signin(payload) {
    return repository.post("login", payload);
  },
  signup(payload) {
    return repository.post("signup", payload);
  },
  refresh(payload) {
    return repository.post("refresh_token", payload);
  },
  me() {
    return repository.get("me");
  },
  edit(payload) {
    return repository.patch("users", payload);
  },
  delete() {
    return repository.delete("users");
  },
  password(payload) {
    return repository.patch("users/password", payload);
  },
  resend() {
    return repository.post("users/resend");
  },
  changeEmail(payload) {
    return repository.post("users/changeEmail", payload);
  },
};
