<template>
  <div>
    <v-app-bar app flat color="white" dark class="pa-0">
      <v-container class="d-flex align-center pa-0">
        <div class="d-flex align-center">
          <template v-if="navigationType === 'survey_detail'">
            <v-icon @click="$router.go(-1)" color="primary">
              mdi-arrow-left-thick
            </v-icon>
            <v-toolbar-title class="main-black-text ml-1">
              <router-link to="/" class="toolbar-title">
                クイックアンケート
              </router-link>
            </v-toolbar-title>
          </template>
          <template v-else-if="navigationType === 'select_question'">
            <v-icon @click="$router.go(-1)" color="primary">
              mdi-arrow-left-thick
            </v-icon>
            <v-toolbar-title class="main-black-text ml-1">
              <router-link to="/" class="toolbar-title">
                質問方式を選択
              </router-link>
            </v-toolbar-title>
          </template>
          <template v-else-if="navigationType === 'template_detail'">
            <v-icon @click="$router.go(-1)" color="primary">
              mdi-arrow-left-thick
            </v-icon>
            <v-toolbar-title class="main-black-text ml-1">
              <router-link to="/" class="toolbar-title">
                テンプレートから質問を選択
              </router-link>
            </v-toolbar-title>
          </template>
          <template v-else-if="navigationType === 'select_template'">
            <v-icon @click="$router.go(-1)" color="primary">
              mdi-arrow-left-thick
            </v-icon>
            <v-toolbar-title class="main-black-text ml-1">
              <router-link to="/" class="toolbar-title">
                テンプレートを選択
              </router-link>
            </v-toolbar-title>
          </template>
          <template v-else-if="navigationType === 'public_answer'">
            <v-toolbar-title class="main-black-text ml-1">
              <router-link to="" class="toolbar-title">
                クイックアンケート
              </router-link>
            </v-toolbar-title>
          </template>
          <template v-else-if="navigationType === 'question_detail'">
            <v-icon @click="$router.go(-1)" color="primary">
              mdi-arrow-left-thick
            </v-icon>
          </template>
          <template v-else>
            <router-link to="/">
              <v-img
                alt="クイックアンケートロゴ"
                class="shrink mr-2"
                contain
                src="../../assets/temp-square-logo.png"
                :width="iconWidth"
              />
            </router-link>
            <v-toolbar-title class="main-black-text">
              <router-link to="/" class="toolbar-title">
                クイックアンケート
              </router-link>
            </v-toolbar-title>
          </template>
        </div>

        <v-spacer></v-spacer>

        <template
          v-if="!isMobile && navigationType !== 'public_answer' && !isLoggedIn"
        >
          <v-btn v-if="!isLP" text @click="goToLP">
            <span class="main-black-text mr-2">ホーム</span>
          </v-btn>

          <v-btn text @click="handleBlog">
            <span class="main-black-text mr-2">ブログ</span>
          </v-btn>

          <v-btn v-if="isLP" text @click="scrollTo('#feature')">
            <span class="main-black-text mr-2">機能</span>
          </v-btn>

          <v-btn v-if="isLP" text @click="scrollTo('#price')">
            <span class="main-black-text mr-2">料金</span>
          </v-btn>

          <v-btn text @click="handleInquiry">
            <span class="main-black-text mr-2">お問い合わせ</span>
          </v-btn>

          <v-btn text @click="login">
            <span class="main-black-text mr-2">ログイン</span>
          </v-btn>

          <v-btn @click="signup" elevation="2" large color="primary">
            <span color="white">無料で会員登録</span>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            v-if="!isMobile && !isPaidUser"
            @click="checkout(`navibar`)"
            color="primary"
          >
            <span color="white">アップグレード</span>
          </v-btn>
          <v-app-bar-nav-icon
            v-if="navigationType !== 'public_answer'"
            color="primary"
            @click.stop="toggleDrawer()"
          >
          </v-app-bar-nav-icon>
        </template>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary>
      <template v-if="!isLoggedIn">
        <v-list nav dense>
          <v-list-item-title>メニュー</v-list-item-title>
          <v-list-item-group v-model="group" active-class="text--accent-4 mt-2">
            <v-list-item inactive v-if="!isLP" @click="goToLP">
              <v-list-item-title>
                <v-icon>
                  mdi-home-circle-outline
                </v-icon>
                ホーム
              </v-list-item-title>
            </v-list-item>

            <v-list-item inactive v-if="isLP" @click="scrollTo('#feature')">
              <v-list-item-title>
                <v-icon>
                  mdi-view-grid-outline
                </v-icon>
                機能
              </v-list-item-title>
            </v-list-item>

            <v-list-item inactive v-if="isLP" @click="scrollTo('#price')">
              <v-list-item-title>
                <v-icon>
                  mdi-currency-usd
                </v-icon>
                料金
              </v-list-item-title>
            </v-list-item>

            <v-list-item inactive @click="handleInquiry">
              <v-list-item-title>
                <v-icon>
                  mdi-account-question-outline
                </v-icon>
                お問い合わせ
              </v-list-item-title>
            </v-list-item>

            <v-list-item inactive @click="handleBlog">
              <v-list-item-title>
                <v-icon>
                  mdi-file-document-edit-outline
                </v-icon>
                ブログ
              </v-list-item-title>
            </v-list-item>

            <v-list-item inactive @click="login">
              <v-list-item-title>
                <v-icon>
                  mdi-login
                </v-icon>
                ログイン
              </v-list-item-title>
            </v-list-item>

            <v-list-item inactive>
              <v-btn
                @click="signup"
                elevation="2"
                large
                color="primary"
                class="register-button mt-4"
              >
                <span color="white">無料で会員登録する</span>
              </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-else>
        <v-list nav dense>
          <v-list-item-title>メニュー</v-list-item-title>
          <v-list-item inactive @click="goToSurveyList" class="mt-2">
            <v-list-item-title>
              <v-icon>
                mdi-clipboard-list-outline
              </v-icon>
              アンケート一覧
            </v-list-item-title>
          </v-list-item>
          <v-list-item inactive @click="goToUserDetail">
            <v-list-item-title>
              <v-icon>
                mdi-account
              </v-icon>
              登録情報
            </v-list-item-title>
          </v-list-item>
          <v-list-item inactive @click="handleInquiry">
            <v-list-item-title>
              <v-icon>
                mdi-account-question-outline
              </v-icon>
              お問い合わせ
            </v-list-item-title>
          </v-list-item>
          <v-list-item inactive @click="logout">
            <v-list-item-title>
              <v-icon>
                mdi-logout
              </v-icon>
              ログアウト
            </v-list-item-title>
          </v-list-item>
          <v-list-item inactive v-if="!isPaidUser" @click="checkout(`drawer`)">
            <v-btn
              @click="checkout(`drawer`)"
              elevation="2"
              large
              width="100%"
              color="primary"
            >
              <span color="white">アップグレード</span>
            </v-btn>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "DynamicAppBar",
  props: [],
  components: {},
  data: () => ({
    drawer: false,
    group: null,
  }),
  computed: {
    isPaidUser() {
      return this.$store.getters["user/isPaidPlan"];
    },
    navigationType() {
      if (this.$route.path === "/") {
        return "default";
      } else if (this.$route.path.endsWith("/select_template")) {
        return "select_template";
      } else if (this.$route.path.endsWith("/template")) {
        return "template_detail";
      } else if (this.$route.path.endsWith("/select_question")) {
        return "select_question";
      } else if (this.$route.path.startsWith("/surveys")) {
        return "survey_detail";
      } else if (this.$route.path.startsWith("/p/")) {
        return "public_answer";
      } else if (this.$route.path.startsWith("/questions/")) {
        return "question_detail";
      }

      return "default";
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isLoggedIn() {
      return this.$store.getters["user/isLoggedIn"];
    },
    isLP() {
      return this.$route.path === "/";
    },
    iconWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 30;
        case "sm":
          return 30;
        case "md":
          return 40;
        case "lg":
          return 40;
        case "xl":
          return 40;
        default:
          return 0;
      }
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    closeDrawer() {
      this.drawer = false;
    },
    handleInquiry() {
      this.closeDrawer();
      this.$emit("handleInquiry");
    },
    handleBlog() {
      this.closeDrawer();
      window.open("https://blog.quick-survey.app/", "_blank");
    },
    logout() {
      this.closeDrawer();
      this.$emit("logout");
    },
    checkout(from) {
      this.closeDrawer();
      this.$emit("checkout", from);
    },
    goToUserDetail() {
      this.closeDrawer();
      this.$router.push("/users/edit");
    },
    goToSurveyList() {
      this.closeDrawer();
      this.$router.replace("/users");
    },
    login() {
      this.closeDrawer();
      this.$router.replace("/signin");
    },
    signup() {
      this.closeDrawer();
      this.$router.replace("/signup");
    },
    goToLP() {
      this.closeDrawer();
      this.$router.replace("/");
    },
    scrollTo(hash) {
      this.closeDrawer();
      this.$scrollTo(hash, { offset: -30 });
    },
  },
};
</script>

<style scoped>
.main-black-text {
  color: #042a2b;
}
.toolbar-title {
  color: inherit;
  text-decoration: inherit;
}
.register-button {
  width: 100%;
}
</style>
