import repository from "../repository";

export default {
  get(id) {
    return repository.get(`public/survey/${id}`);
  },
  submit(payload) {
    return repository.post("public/submit", payload);
  },
  log(payload) {
    return repository.post("public/survey/log", payload);
  },
  confirmEmail(token, type) {
    return repository.get(`public/confirmEmail?token=${token}&type=${type}`);
  },
  resetPassword(payload) {
    return repository.post("public/resetPassword", payload);
  },
  changePassword(payload) {
    return repository.post("public/changePassword", payload);
  },
};
