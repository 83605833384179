<template>
  <div>
    <h2>
      Q{{ index }}. {{ question.questionTitle
      }}<span v-if="question.isRequired"> *</span>
      <template
        v-if="question.type === 'selection_question' && !question.isMultiple"
      >
        <br />(１つ選択してください。)
      </template>
      <template
        v-if="question.type === 'selection_question' && question.isMultiple"
      >
        <br />(複数選択が可能です。)
      </template>
      <template v-if="question.type === 'number_question'">
        <br />({{ question.minNumber }}から{{
          question.maxNumber
        }}の間でご回答お願いします)
      </template>
    </h2>

    <div class="ma-2 mt-0 px-2 question-preview-container">
      <template v-if="question.type === 'selection_question'">
        <div
          v-for="item in question.selectionItems"
          :key="item.id"
          style="display: flex;"
        >
          <div
            v-if="!question.isMultiple"
            style="height: 20px; width: 20px; border: 1px solid black; border-radius: 10px;"
            color="#D8D8D8"
          ></div>
          <div
            v-if="question.isMultiple"
            style="height: 20px; width: 20px; border: 1px solid black; border-radius: 2px;"
            color="#D8D8D8"
          ></div>
          <div style="margin-left: 5px;">{{ item.itemTitle }}</div>
        </div>
      </template>
      <template
        v-if="
          question.type === 'text_question' ||
            question.type === 'number_question'
        "
      >
        <div
          class="print-text-box"
          v-bind:style="{ height: textBoxHeigh() + 'px' }"
        ></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintQuestion",
  props: ["question", "index"],
  components: {},
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {
    textBoxHeigh() {
      return 50;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
