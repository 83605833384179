export default {
  namespaced: true,
  state: {
    access_token: "",
    refresh_token: "",
    is_paid_plan: false,
  },
  getters: {
    accessToken: (state) => {
      return state.access_token;
    },
    refreshToken: (state) => {
      return state.refresh_token;
    },
    isLoggedIn: (state) => {
      return state.refresh_token !== "" && state.access_token !== "";
    },
    isPaidPlan: (state) => {
      return state.is_paid_plan;
    },
  },
  mutations: {
    create(state, data) {
      state.access_token = data.access_token;
      state.refresh_token = data.refresh_token;
    },
    destroy(state) {
      state.access_token = "";
      state.refresh_token = "";
      state.is_paid_plan = false;
    },
    upgradePlan(state, is_paid_plan) {
      state.is_paid_plan = is_paid_plan;
    },
  },
};
