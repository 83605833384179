import axios from "axios";

export default {
  fetchTemplates() {
    const client = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "json",
    });
    return client.get(
      "https://quick-survey-resources.s3-ap-northeast-1.amazonaws.com/template/template.json"
    );
  },
};
