<template>
  <v-container>
    <v-row class="d-flex answer_top">
      <v-col cols="12">
        <h1 class="survey-title">{{ survey.surveyTitle }}</h1>
        <v-img
          alt="アンケートの開始画像"
          class="shrink mt-4 mx-auto"
          contain
          max-width="450"
          src="../../assets/answer-top.png"
        />
        <p class="survey-description mt-4">
          「アンケートの回答を開始する」ボタンから<br />アンケートの回答を始めましょう！
        </p>
      </v-col>

      <div class="floating-button-area">
        <v-btn
          color="primary"
          width="90%"
          height="45px"
          max-width="450px"
          class="mx-auto"
          v-if="isSurveyAvailable"
          @click="onStartSurvey"
        >
          アンケートの回答を開始する
        </v-btn>

        <p class="powered-by-text mt-3 mb-0">
          このアンケートは
          <a href="https://quick-survey.app/?utm_source=quick-survey&utm_medium=survey-start&utm_campaign=powered-by" target="_blank">
            クイックアンケート
          </a>
          により作成されました
        </p>
      </div>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "StartAnswer",
  components: {},
  props: {
    survey: {
      type: Object,
      require: true,
      default() {
        return {};
      },
    },
    isSurveyAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hasError: false,
      snackbar: false,
      snackMessage: "",
    };
  },
  watch: {
    isSurveyAvailable: function(val) {
      this.hasError = !val;
      if (this.hasError) {
        this.snackbar = true;
        this.snackMessage = "不正なページです";
      } else {
        this.snackbar = false;
        this.snackMessage = "";
      }
    },
  },
  computed: {},
  validations: {},
  methods: {
    onStartSurvey() {
      if (!this.hasError) {
        this.$emit("onStartSurvey");
      }
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.survey-title {
  color: #042a2b;
  font-size: 16px;
}
.survey-description {
  color: #042a2b;
  font-size: 12px;
  text-align: center;
}
.floating-button-area {
  display: flex;
  flex-direction: column;

  position: fixed;
  max-width: 500px;
  height: 80px;
  margin: auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  bottom: 10px;
}
.powered-by-text {
  text-align: center;
  color: #00000099;
  font-size: 0.7em;
}
</style>
