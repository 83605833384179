<template>
  <v-container>
    <v-row
      class="d-flex not-ready-survey-container"
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <h1 class="not-ready-survey-title">
          アンケートの配信準備が<br />まだ完了していません。
        </h1>

        <v-img
          alt="アンケートの準備中画像"
          class="shrink .not-ready-image mt-4 mx-auto"
          contain
          max-width="450"
          src="../../../assets/survey-not-ready.png"
        />
        <p class="not-ready-description mt-4">
          「編集」画面から<br />アンケートを有効化し、<br />質問を１つ以上追加しましょう！
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SurveyNotReady",
  components: {},
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.not-ready-survey-container {
  text-align: center;
  margin: auto;
}
.not-ready-survey-title {
  color: #042a2b;
  font-size: 16px;
}
.not-ready-image {
  margin: 0 auto;
  text-align: center;
}
.not-ready-description {
  color: #042a2b;
  font-size: 12px;
}
</style>
