<template>
  <v-container>
    <v-row class="d-flex">
      <v-col cols="12">
        <h1 class="survey-title">ご回答ありがとうございました！</h1>
        <v-img
          alt="アンケートの完了画像"
          class="shrink mt-4 mx-auto"
          contain
          max-width="450"
          src="../../assets/answer-end.png"
        />
        <p class="survey-description mt-4">
          全ての回答を送信しました。<br />ご協力ありがとうございました！
        </p>
      </v-col>
      <div class="floating-button-area">
        <v-btn
          class="mx-auto"
          color="primary"
          width="90%"
          height="45px"
          max-width="450px"
          @click="goToLP"
        >
          クイックアンケートへ戻る
        </v-btn>
        <p class="powered-by-text mt-3 mb-0">
          このアンケートは
          <a href="https://quick-survey.app/?utm_source=quick-survey&utm_medium=survey-end&utm_campaign=powered-by" target="_blank">
            クイックアンケート
          </a>
          により作成されました
        </p>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EndAnswer",
  components: {},
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  validations: {},
  methods: {
    goToLP() {
      this.$router.replace("/");
    },
  },
  async mounted() {},
};
</script>

<style scoped>
.survey-title {
  color: #042a2b;
  font-size: 16px;
  text-align: center;
}
.survey-description {
  color: #042a2b;
  font-size: 12px;
  text-align: center;
}
.floating-button-area {
  display: flex;
  flex-direction: column;

  position: fixed;
  max-width: 500px;
  height: 80px;
  margin: auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  bottom: 10px;
}
.powered-by-text {
  text-align: center;
  color: #00000099;
  font-size: 0.7em;
}
</style>
