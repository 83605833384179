var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"targets-area pt-6 pb-3 pt-md-12 pb-md-6"},[_c('div',{staticClass:"targets-title-area mb-3 mb-md-12",class:_vm.$vuetify.breakpoint.smAndDown ? 'targets-title-mobile' : 'targets-title'},[_c('h2',[_vm._v("あらゆる業種・シーンで")])]),_c('v-container',{attrs:{"pt-0":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'target-item-title-mobile'
              : 'target-item-title'},[_c('v-img',{staticClass:"shrink target-image",attrs:{"alt":"習い事教室アイコン","contain":"","src":require("../../assets/ic_school.png"),"max-height":"75"}}),_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v("習い事教室")])])],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'target-item-title-mobile'
              : 'target-item-title'},[_c('v-img',{staticClass:"shrink target-image",attrs:{"alt":"整体・マッサージアイコン","contain":"","src":require("../../assets/ic_people.png"),"max-height":"75"}}),_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v("整体・マッサージ")])])],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'target-item-title-mobile'
              : 'target-item-title'},[_c('v-img',{staticClass:"shrink target-image",attrs:{"alt":"エステ・ネイルアイコン","contain":"","src":require("../../assets/ic_local_florist.png"),"max-height":"75"}}),_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v("エステ・ネイル")])])],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'target-item-title-mobile'
              : 'target-item-title'},[_c('v-img',{staticClass:"shrink target-image",attrs:{"alt":"歯医者・病院アイコン","contain":"","src":require("../../assets/ic_local_hospital.png"),"max-height":"75"}}),_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v("歯医者・病院")])])],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'target-item-title-mobile'
              : 'target-item-title'},[_c('v-img',{staticClass:"shrink target-image",attrs:{"alt":"美容院アイコン","contain":"","src":require("../../assets/ic_face.png"),"max-height":"75"}}),_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v("美容院")])])],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('div',{class:_vm.$vuetify.breakpoint.smAndDown
              ? 'target-item-title-mobile'
              : 'target-item-title'},[_c('v-img',{staticClass:"shrink target-image",attrs:{"alt":"飲食店アイコン","contain":"","src":require("../../assets/ic_local_dining.png"),"max-height":"75"}}),_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v("飲食店")])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }