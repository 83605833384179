<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title class="pb-0">
        <span class="headline">パスワードの変更</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field
          v-model="oldPassword"
          label="今のパスワード"
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="newPassword"
          label="新しいパスワード"
          type="password"
        ></v-text-field>
        <v-text-field
          v-model="newPassword2"
          label="新しいパスワードの確認"
          type="password"
        ></v-text-field>
        <p class="mt-1 mb-1 pl-0 custom-error" v-if="errorMsg.length > 0">
          {{ errorMsg }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          閉じる
        </v-btn>
        <v-btn color="primary" @click="saveClicked">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      oldPassword: "",
      newPassword: "",
      newPassword2: "",
      errorMsg: "",
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.clearInputtedPasswords();
      this.$emit("close");
    },
    saveClicked() {
      if (!this.validateInput()) {
        return;
      }
      const obj = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      this.$emit("save", obj);
      this.clearInputtedPasswords();
    },
    validateInput() {
      if (
        this.oldPassword.length <= 0 ||
        this.newPassword.length <= 0 ||
        this.newPassword2.length <= 0
      ) {
        this.errorMsg = "全ての項目を入力してください";
        return false;
      }
      if (this.newPassword != this.newPassword2) {
        this.errorMsg = "新しいパスワードが一致していません";
        return false;
      }

      if (this.newPassword.length < 8) {
        this.errorMsg = "新しいパスワードが短いです";
        return false;
      }

      this.errorMsg = "";
      return true;
    },
    clearInputtedPasswords() {
      this.oldPassword = "";
      this.newPassword = "";
      this.newPassword2 = "";
      this.errorMsg = "";
    },
  },
};
</script>

<style scoped>
.headline {
  color: #042a2b;
  font-size: 16px !important;
}
.custom-error {
  color: #db2d16 !important;
  padding-left: 12px;
  font-size: 12px;
}
</style>
