<template>
  <div class="detail-container">
    <v-container>
      <v-row class="d-flex">
        <v-col cols="12">
          <h1 class="question-title">
            {{ questionDetail.question.questionTitle }}
          </h1>
        </v-col>
        <v-col cols="12">
          <template v-if="questionDetail.question.type === 'text_question'">
            <v-card elevation="2">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">日付</th>
                    <th class="text-left">回答</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="answer in questionDetail.answers" :key="answer.id">
                    <td>{{ answer.createdAt }}</td>
                    <td>{{ answer.answerText }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </template>
          <template
            v-else-if="questionDetail.question.type === 'number_question'"
          >
            <v-card elevation="2">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">日付</th>
                    <th class="text-left">回答</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="answer in questionDetail.answers" :key="answer.id">
                    <td>{{ answer.createdAt }}</td>
                    <td>{{ answer.answerNumber }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </template>
          <template
            v-else-if="questionDetail.question.type === 'selection_question'"
          >
            <v-card elevation="2">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left">日付</th>
                    <th class="text-left">回答</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="answer in questionDetail.answers" :key="answer.id">
                    <td>{{ answer.createdAt }}</td>
                    <td>{{ constructSelectionAnswer(answer) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
const QuestionRepository = RepositoryFactory.get("questions");

export default {
  name: "QuestionDetail",
  components: {},
  data: () => ({
    questionDetail: {
      answers: [],
      question: {},
    },
  }),
  computed: {},
  validations: {},
  methods: {
    fetchQuestionDetail(id) {
      QuestionRepository.detail(id)
        .then((res) => {
          this.questionDetail = res.data.body;
        })
        .catch(() => {});
    },
    constructSelectionAnswer(answer) {
      return answer.items.map((i) => i.questionItem.itemTitle).join();
    },
  },
  async mounted() {
    this.fetchQuestionDetail(this.$route.params.id);
  },
};
</script>

<style scoped>
.detail-container {
  background-color: #f6f6f6;
  height: 100%;
}
.question-title {
  color: #042a2b;
  font-size: 16px;
}
</style>
