<template>
  <v-app>
    <dynamic-app-bar
      @handleInquiry="handleInquiry"
      @logout="logout"
      @checkout="checkout"
    ></dynamic-app-bar>

    <v-snackbar color="primary" v-model="snackbar" timeout="2000000">
      ご登録ありがとうございます🙇‍♂️
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <router-view v-on:goToSignup="goToSignup" />
    </v-main>
  </v-app>
</template>

<script>
import DynamicAppBar from "./components/app/DynamicAppBar.vue";

export default {
  name: "App",
  components: {
    DynamicAppBar,
  },
  metaInfo: {
    title: "クイックアンケート | 簡単でシンプルなWebアンケートサービス",
    meta: [
      {
        name: "description",
        content:
          "クイックアンケートは数クリックでWebアンケートを作成できるWebアンケートサイトです。テンプレート選択のみでアンケートの準備が完了します。QRコードやSNSで配信も簡単です。回答用紙の印刷機能も！",
      },
    ],
  },
  computed: {},
  watch: {},
  data: () => ({
    snackbar: false,
    originPlace: "",
  }),
  methods: {
    goToSignup(name) {
      this.$ga.event("open-signup", "click", name);
      this.$router.push("/signup");
    },
    handleInquiry() {
      window.open(
        "https://mail.google.com/mail/?view=cm&fs=1&to=kazuya@quick-survey.app&su=【クイックアンケート】お問い合わせ",
        "_blank"
      );
    },
    logout() {
      this.$store.commit("user/destroy");
      this.$router.replace("/");
    },
    checkout(from) {
      this.$ga.event("open-checkout", "click", from);
      this.$router.push("/checkout");
    },
  },
};
</script>

<style scoped></style>
