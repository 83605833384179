import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
import Landing from "@/pages/Landing.vue";
import PrivacyPolicy from "@/pages/PrivacyPolicy.vue";
import Term from "@/pages/Term.vue";
import Signup from "@/pages/Signup.vue";
import Signin from "@/pages/Signin.vue";
import UserTop from "@/pages/UserTop.vue";
import SurveyDetail from "@/pages/SurveyDetail.vue";
import SelectQuestionType from "@/pages/SelectQuestionType.vue";
import PublicAnswerTop from "@/pages/PublicAnswerTop.vue";
import QuestionDetail from "@/pages/QuestionDetail.vue";
import UserDetail from "@/pages/UserDetail.vue";
import ConfirmSignupEmail from "@/pages/ConfirmSignupEmail.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import Checkout from "@/pages/Checkout.vue";
import SelectTemplate from "@/pages/SelectTemplate.vue";
import TemplateDetail from "@/pages/TemplateDetail.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Landing,
    },
    {
      path: "/signup",
      component: Signup,
    },
    {
      path: "/signin",
      component: Signin,
    },
    {
      path: "/users",
      component: UserTop,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/users/edit",
      component: UserDetail,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/surveys/:id",
      name: "survey",
      component: SurveyDetail,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/surveys/:id/select_question",
      name: "select_question",
      component: SelectQuestionType,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/surveys/:id/select_template",
      name: "select_template",
      component: SelectTemplate,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/surveys/:id/template",
      name: "template_detail",
      component: TemplateDetail,
      props: (route) => ({
        ...route.params,
      }),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/questions/:id",
      name: "question",
      component: QuestionDetail,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/p/resetPassword",
      name: "reset_password",
      component: ResetPassword,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/p/confirmRegistration",
      name: "confirm_signup",
      component: ConfirmSignupEmail,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/p/:id",
      name: "answer_top",
      component: PublicAnswerTop,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/checkout",
      component: Checkout,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/privacy",
      component: PrivacyPolicy,
    },
    {
      path: "/term",
      component: Term,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["user/isLoggedIn"];
  if (to.matched.some((page) => page.meta.authRequired) && !isLoggedIn) {
    next("/");
  } else if (to.path == "/" && isLoggedIn) {
    next("/users");
  } else {
    next();
  }
});

export default router;
