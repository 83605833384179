<template>
  <div id="price" class="prices-area pt-3 pb-3 pt-md-6 pb-md-6">
    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12" md="4">
          <div class="price-item-area-white">
            <div class="mt-8">
              <p class="price-title-primary">
                <span class="bigger-amount-title">0</span>円/月
              </p>
              <p class="price-text-color-gray">初期費用無料</p>
            </div>
            <hr class="price-border-color-gray" />
            <div class="mt-4 mr-4 ml-4 price-description-area">
              <p class="price-text-color-gray">
                まずは、無料体験をお試しください。機能は制限されていますが、どのようなサービスか知りたい方には最適です。
              </p>
            </div>
            <div class="mt-4 mb-8">
              <v-btn
                @click="goToSignup('Price1')"
                elevation="3"
                large
                color="primary"
                class="register-button"
                :height="height"
                :width="width"
              >
                <span color="white">無料で会員登録する</span>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="price-item-area-primary">
            <div class="mt-8">
              <p class="price-title-white">
                <span class="bigger-amount-title">1,980</span>円/月
              </p>
              <p class="price-text-color-white">初期費用無料</p>
            </div>
            <hr class="price-border-color-white" />
            <div class="mt-4 mr-4 ml-4 price-description-area">
              <p class="price-text-color-white">
                クイックアンケートの全ての機能が使える基本プラン。類似サービスと比べても格安でご利用いただけます。
              </p>
            </div>
            <div class="mt-4 mb-8">
              <v-btn
                @click="goToSignup('Price2')"
                elevation="3"
                large
                color="white"
                class="register-button"
                :height="height"
                :width="width"
              >
                <span class="price-text-color-primary">無料で会員登録する</span>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div class="price-item-area-white">
            <div class="mt-8">
              <p class="price-title-primary">
                <span class="bigger-amount-title">ご相談</span>
              </p>
              <p class="price-text-color-gray">初期費用無料</p>
            </div>
            <hr class="price-border-color-gray" />
            <div class="mt-4 mr-4 ml-4 price-description-area">
              <p class="price-text-color-gray">
                現状の機能に満足できない場合はご相談ください。まだまだ改善途中のサービス。ご意見・ご要望を取り入れ柔軟に対応します
              </p>
            </div>
            <div class="mt-4 mb-8">
              <v-btn
                @click="goToSignup('Price3')"
                elevation="3"
                large
                color="primary"
                class="register-button"
                :height="height"
                :width="width"
              >
                <span color="white">無料で会員登録する</span>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Prices",
  methods: {
    goToSignup(name) {
      this.$emit("goToSignup", name);
    },
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 44;
        case "sm":
          return 44;
        case "md":
          return 44;
        case "lg":
          return 55;
        case "xl":
          return 55;
        default:
          return 0;
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 205;
        case "sm":
          return 205;
        case "md":
          return 205;
        case "lg":
          return 280;
        case "xl":
          return 280;
        default:
          return 0;
      }
    },
  },
};
</script>

<style scoped>
.prices-area {
  background-color: #f6f8f9;
}
.price-item-area-white {
  text-align: center;
  background: white;
  border: #e7eaee solid 1px;
  border-radius: 5px;
}
.price-item-area-primary {
  text-align: center;
  background: #ee6352;
  border-radius: 5px;
  border: #e7eaee solid 1px;
}
.price-title-white {
  color: white;
  font-size: 28px;
}
.price-title-primary {
  color: #ee6352;
  font-size: 28px;
}
.bigger-amount-title {
  font-size: 48px;
}
.price-text-color-gray {
  color: #7e8085;
}
.price-text-color-white {
  color: white;
}
.price-text-color-primary {
  color: #ee6352;
}
.price-border-color-white {
  background-color: white;
  border: none;
  height: 1px;
}
.price-border-color-gray {
  background-color: #e7eaee;
  border: none;
  height: 1px;
}
</style>
