<template>
  <div>
    <Hero @goToSignup="goToSignup" />
    <register-button comp-name="UnderHero" @goToSignup="goToSignup" />
    <Targets />
    <register-button comp-name="UnderTarget" @goToSignup="goToSignup" />
    <Features />
    <register-button comp-name="UnderFeature" @goToSignup="goToSignup" />
    <Prices @goToSignup="goToSignup" />
    <register-button comp-name="UnderPrice" @goToSignup="goToSignup" />
    <Footer />
  </div>
</template>

<script>
import Hero from "../components/landing/Hero.vue";
import RegisterButton from "../components/landing/RegisterButton.vue";
import Targets from "../components/landing/Targets.vue";
import Features from "../components/landing/Features.vue";
import Prices from "../components/landing/Prices.vue";
import Footer from "../components/app/Footer.vue";

export default {
  name: "Landing",

  components: {
    Hero,
    RegisterButton,
    Targets,
    Features,
    Prices,
    Footer,
  },
  methods: {
    goToSignup(name) {
      this.$emit("goToSignup", name);
    },
  },
};
</script>

<style scoped>
.main-title {
  color: #042a2b;
}
.sub-decription {
  color: #7e8085;
}
</style>
