import repository from "../repository";

export default {
  create(payload) {
    return repository.post("questions", payload);
  },
  bulk(payload) {
    return repository.post("questions/bulk", payload);
  },
  edit(payload) {
    return repository.patch("questions", payload);
  },
  delete(payload) {
    return repository.delete("questions", { data: payload });
  },
  detail(id) {
    return repository.get(`questions/${id}`);
  },
};
