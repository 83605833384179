import axios from "axios";
import store from "../store/index";
import { RepositoryFactory } from "../api/repositoryFactory";
import router from "../router";

const baseDomain =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://api.quick-survey.app";
const baseURL = `${baseDomain}/api/v1`;

const axiosApiInstance = axios.create({
  baseURL,
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers.common[
      "Authorization"
    ] = `Bearer ${store.getters["user/accessToken"]}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
function createAxiosResponseInterceptor() {
  const interceptor = axiosApiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function(error) {
      // Refresh if token is expired...
      if (
        error.response.status === 401 &&
        error.response.data.header.code === "ERROR_9997"
      ) {
        axiosApiInstance.interceptors.response.eject(interceptor);

        // Execute refresh logic
        const UserRepository = RepositoryFactory.get("users");
        const payload = { refreshToken: store.getters["user/refreshToken"] };

        return UserRepository.refresh(payload)
          .then((res) => {
            // Store the new tokens and set for the original request
            store.commit("user/create", res.data.body);
            error.response.config.headers["Authorization"] =
              "Bearer " + res.data.body.access_token;
            return axios(error.response.config);
          })
          .catch((error) => {
            // Clear the tokens since refresh failed
            store.commit("user/destroy");
            router.replace("/");
            return Promise.reject(error);
          })
          .finally(createAxiosResponseInterceptor());
      } else {
        if (
          error.response.status === 401 &&
          error.response.data.header.code === "ERROR_0001"
        ) {
          // Clear the tokens since refresh failed
          store.commit("user/destroy");
          router.replace("/");
          return Promise.reject(error);
        }

        // Return error if it's normal errors
        return Promise.reject(error);
      }
    }
  );
}

createAxiosResponseInterceptor();

export default axiosApiInstance;
