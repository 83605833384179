import repository from "../repository";

export default {
  get(id) {
    return repository.get(`surveys/${id}`);
  },
  surveys() {
    return repository.get("surveys");
  },
  create(payload) {
    return repository.post("surveys", payload);
  },
  delete(payload) {
    return repository.delete("surveys", { data: payload });
  },
  edit(payload) {
    return repository.patch("surveys", payload);
  },
  analytics(id) {
    return repository.get(`surveys/${id}/analytics`);
  },
};
