<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <h1>無料の会員登録</h1>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-form>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="メールアドレス"
            type="email"
            autocomplete="username"
            required
            ref="emailField"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="new-password"
            label="パスワード"
            counter
            @click:append="showPassword = !showPassword"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <hr class="mt-6" />
    <v-row class="mt-1">
      <v-col cols="12">
        <v-row align="center" justify="center" class="mr-0 ml-0">
          <p>
            アカウントをお持ちの方は、
            <router-link to="signin">こちらからログイン</router-link>
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { RepositoryFactory } from "../api/repositoryFactory";
const UserRepository = RepositoryFactory.get("users");

export default {
  name: "Signup",
  components: {},
  metaInfo: {
    title: "無料会員登録 | クイックアンケート",
  },
  data: () => ({
    loading: false,
    password: "",
    email: "",
    showPassword: false,
    snackbar: false,
    errorMessage: "会員登録に失敗しました。",
    timeout: 2000,
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email &&
        errors.push("正しいメールアドレスを入力してください");
      !this.$v.email.required && errors.push("メールアドレスは必須です");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("パスワードは8文字以上で入力してください");
      !this.$v.password.required && errors.push("パスワードは必須です");
      return errors;
    },
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) },
  },
  async mounted() {
    this.$refs.emailField.focus()
  },
  methods: {
    signup() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.registerNewUser(this.email, this.password);
      }
    },
    registerNewUser(email, password) {
      this.loading = true;
      const payload = {
        username: email,
        password: password,
      };
      UserRepository.signup(payload)
        .then((response) => {
          this.loading = false;
          this.$store.commit("user/create", response.data.body.token_info);
          this.$router.push({ path: "/users", query: { from: "signup" } });
          this.$ga.event("signup-complete", "click");
        })
        .catch(() => {
          this.loading = false;
          this.snackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 400px;
}
.sign-up-button {
  font-size: 16px;
}
</style>
