<template>
  <v-container>
    <v-row
      class="d-flex empty-survey-container"
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <h1 class="empty-survey-title">
          まだ、１つもアンケートが<br />作成されていません。
        </h1>

        <v-img
          alt="アンケートなし画像"
          class="shrink .empty-image mt-4 mx-auto"
          contain
          max-width="450"
          src="../../assets/surve-empty-image.png"
        />
        <p class="empty-description mt-4">
          「アンケートを追加する」ボタンから<br />最初のアンケートを追加しましょう！
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EmptySurveyList",
  components: {},
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.empty-survey-container {
  text-align: center;
  margin: auto;
}
.empty-survey-title {
  color: #042a2b;
  font-size: 16px;
}
.empty-image {
  margin: 0 auto;
  text-align: center;
}
.empty-description {
  color: #042a2b;
  font-size: 12px;
}
</style>
