<template>
  <div class="survey_edit_container">
    <template v-if="survey !== undefined">
      <survey-detail-questions
        v-if="survey.questions !== undefined && survey.questions.length !== 0"
        :survey="survey"
        @refreshSurvey="refreshSurvey"
        @addQuestion="addNewQuestion"
        @addQuestionFromTemplate="addQuestionFromTemplate"
      ></survey-detail-questions>
      <survey-detail-empty-question
        v-if="survey.questions !== undefined && survey.questions.length === 0"
        :survey="survey"
        @addQuestion="addNewQuestion"
        @addQuestionFromTemplate="addQuestionFromTemplate"
      ></survey-detail-empty-question>
    </template>
  </div>
</template>

<script>
import SurveyDetailEmptyQuestion from "./SurveyDetailEmptyQuestion.vue";
import SurveyDetailQuestions from "./SurveyDetailQuestions.vue";

export default {
  name: "SurveyDetailEdit",
  props: ["survey"],
  components: { SurveyDetailEmptyQuestion, SurveyDetailQuestions },
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {
    addNewQuestion() {
      this.$router.push(`/surveys/${this.$route.params.id}/select_question`);
    },
    addQuestionFromTemplate() {
      this.$router.push(`/surveys/${this.$route.params.id}/select_template`);
    },
    refreshSurvey() {
      this.$emit("refreshSurvey");
    },
  },
};
</script>

<style scoped>
.survey_edit_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
</style>
