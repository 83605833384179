<template>
  <div class="user_top_container">
    <survey-list
      class="mb-12"
      v-if="surveys.length !== 0"
      :surveys="surveys"
    ></survey-list>
    <empty-survey-list v-else></empty-survey-list>
    <v-btn
      fixed
      bottom
      color="primary"
      width="90%"
      height="45px"
      max-width="450px"
      @click="addNewSurvey"
    >
      アンケートを追加する
    </v-btn>
    <survey-dialog
      v-model="dialog"
      type="add"
      v-on:saveClicked="saveNewSurveyClicked($event)"
      v-on:close="closeDialog"
    ></survey-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="paidSnack" :timeout="timeout">
      {{ paidSnackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="goToUpgrade">
          詳しく見る
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
const SurveyRepository = RepositoryFactory.get("surveys");
const UserRepository = RepositoryFactory.get("users");
import SurveyList from "../components/survey/SurveyList.vue";
import EmptySurveyList from "../components/survey/EmptySurveyList.vue";
import SurveyDialog from "../components/dialogs/SurveyDialog.vue";

export default {
  name: "UserTop",
  components: {
    SurveyList,
    EmptySurveyList,
    SurveyDialog,
  },
  data: () => ({
    surveys: [],
    dialog: false,
    snackbar: false,
    paidSnack: false,
    errorMessage: "",
    paidSnackMessage: "",
    timeout: 2000,
  }),
  computed: {},
  validations: {},
  methods: {
    addNewSurvey() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    goToUpgrade() {
      this.$ga.event("open-checkout", "click", "survey-limit-snack");
      this.$router.push("/checkout");
    },
    saveNewSurveyClicked(surveyName) {
      this.$ga.event("add-survey");
      this.closeDialog();
      SurveyRepository.create({ title: surveyName })
        .then(() => {
          this.refreshSurves();
        })
        .catch((error) => {
          if (error.response.data.header.code == "ERROR_9697") {
            this.paidSnackMessage =
              "無料プランでは、アンケートは１つまで作成できます。";
            this.paidSnack = true;
          } else {
            this.errorMessage =
              "アンケートの追加に失敗しました。お時間を空けてお試しください。";
            this.snackbar = true;
          }
        });
    },
    async refreshSurves() {
      SurveyRepository.surveys()
        .then((res) => {
          this.surveys = res.data.body.surveys;
        })
        .catch(() => {});
    },
    async refreshUserInfo() {
      UserRepository.me()
        .then((res) => {
          const isPaidPlan = res.data.body.roles.some(
            (c) => c.role === "PREMIUM_MEMBER" || c.role === "ADMIN"
          );
          this.$store.commit("user/upgradePlan", isPaidPlan);
        })
        .catch(() => {});
    },
  },
  async mounted() {
    this.refreshSurves();
    this.refreshUserInfo();

    if (this.$route.query.from === "signup") {
      this.errorMessage =
        "会員登録ありがとうございます。確認メール内のボタンをクリックしてください。";
      this.snackbar = true;
      this.$router.replace(this.$route.path);
    }
  },
};
</script>

<style scoped>
.user_top_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
</style>
