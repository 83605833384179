<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title class="pb-0">
        <span class="headline">{{ title }}</span>
        <v-icon
          v-if="type === 'edit'"
          size="28"
          color="red lighten-1"
          @click="deleteSurvey()"
          >mdi-delete-outline
        </v-icon>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field
          v-model="internalSurveyName"
          :error-messages="surveyNameErrors"
          label="新しいアンケートの名前"
          required
          @input="$v.internalSurveyName.$touch()"
          @blur="$v.internalSurveyName.$touch()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          閉じる
        </v-btn>
        <v-btn color="primary" @click="saveClicked">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    value: {
      type: Boolean,
    },
    type: {
      type: String,
      require: true,
      default: "add", // 'add' or 'edit'
    },
    surveyTitle: {
      type: String,
      require: false,
      default: "",
    },
  },
  data: function() {
    return {
      internalSurveyName: this.surveyTitle,
    };
  },
  watch: {
    surveyTitle: function(val) {
      this.internalSurveyName = val;
    },
  },
  computed: {
    title() {
      return this.type == "add" ? "アンケートの追加" : "アンケート名の編集";
    },
    surveyNameErrors() {
      const errors = [];
      if (!this.$v.internalSurveyName.$dirty) return errors;
      !this.$v.internalSurveyName.required &&
        errors.push("アンケート名は必須です");
      return errors;
    },
  },
  methods: {
    closeDialog() {
      this.clearAddingSurveyIfNeeded();
      this.$emit("close");
    },
    saveClicked() {
      this.$v.internalSurveyName.$touch();
      if (this.$v.internalSurveyName.$invalid) {
        return;
      }

      this.$emit("saveClicked", this.internalSurveyName);
      this.clearAddingSurveyIfNeeded();
    },
    deleteSurvey() {
      this.$emit("deleteSurvey");
    },
    clearAddingSurveyIfNeeded() {
      if (this.type == "add") {
        this.internalSurveyName = "";
        this.$v.internalSurveyName.$reset();
      }
    },
  },
  validations: {
    internalSurveyName: {
      required,
    },
  },
};
</script>

<style scoped>
.headline {
  color: #042a2b;
  font-size: 16px !important;
}
</style>
