<template>
  <v-container>
    <v-row class="d-flex">
      <v-col cols="12">
        <h1 class="survey-list-title">アンケート一覧</h1>
      </v-col>
      <template v-for="survey in surveys">
        <v-col cols="12" sm="4" :key="survey.id" class="py-0">
          <survey-item
            :survey="survey"
            @goToSurveyDetail="goToSurveyDetail"
          ></survey-item>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import SurveyItem from "./SurveyItem.vue";

export default {
  name: "SurveyList",
  props: ["surveys"],
  components: {
    SurveyItem,
  },
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {
    goToSurveyDetail(survey, tab) {
      this.$router.push({
        path: `/surveys/${survey.id}`,
        query: { tab: tab },
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.survey-list-title {
  color: #042a2b;
  font-size: 16px;
}
</style>
