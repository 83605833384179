<template>
  <div class="template_detail_container">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="template-detail-title">
            {{ titleText }}
          </h1>
        </v-col>
        <template v-for="question in questions">
          <v-col cols="12" :key="question.title" class="py-0">
            <v-checkbox
              hide-details
              v-model="question.selected"
              :label="
                question.selected
                  ? 'この質問をアンケートに追加します'
                  : 'この質問はアンケートに追加されません'
              "
            ></v-checkbox>
            <question-item
              :question="question"
              :isTemplatePreview="true"
            ></question-item>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <v-btn
      fixed
      bottom
      right
      left
      color="primary"
      height="45px"
      max-width="450px"
      width="90%"
      class="mx-auto"
      :disabled="!addButtonActive"
      @click="saveSelectedQuestions"
    >
      {{ buttonText }}
    </v-btn>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import QuestionItem from "../components/survey_detail/edit/QuestionItem.vue";
import { RepositoryFactory } from "../api/repositoryFactory";
const QuestionRepository = RepositoryFactory.get("questions");

export default {
  name: "TemplateDetail",
  props: ["template"],
  components: { QuestionItem },
  data: () => ({
    questions: [],
    snackbar: false,
    snackMessage: "",
  }),
  computed: {
    addButtonActive() {
      return this.selectedNumber > 0;
    },
    selectedNumber() {
      return this.questions.filter((ele) => ele.selected).length;
    },
    titleText() {
      return `${this.selectedNumber}/${this.questions.length}の質問を選択中`;
    },
    buttonText() {
      if (this.selectedNumber === this.questions.length) {
        return `全ての質問(${this.questions.length}個)を追加する`;
      } else if (this.selectedNumber === 0) {
        return "追加する質問を選択してください";
      } else {
        return `${this.selectedNumber}/${this.questions.length}の質問を追加`;
      }
    },
    surveyId() {
      return this.$route.params.id;
    },
  },
  validations: {},
  methods: {
    selectAllQuestions() {
      const that = this;
      this.questions.forEach(function(element) {
        that.$set(element, "selected", true);
      });
    },
    saveSelectedQuestions() {
      const selectedQuestions = this.questions.filter((ele) => ele.selected);
      this.$ga.event(
        "add-questions",
        "template",
        selectedQuestions.length.toString()
      );
      const payload = this.createQuestionBulkPayload(selectedQuestions);
      this.executeBulkQuestion(payload);
    },
    createQuestionBulkPayload(selectedQuestions) {
      const targetSurveyId = this.surveyId;
      const result = { surveyId: targetSurveyId, questions: [] };
      selectedQuestions.forEach(function(questionPayload) {
        switch (questionPayload.type) {
          case "text_question": {
            const textPayload = {
              type: questionPayload.type,
              surveyId: targetSurveyId,
              textQuestionRequest: {
                questionTitle: questionPayload.questionTitle,
                maxLength: questionPayload.maxLength,
              },
              isRequired: questionPayload.isRequired,
            };
            result.questions.push(textPayload);
            break;
          }
          case "number_question": {
            const numberPayload = {
              type: questionPayload.type,
              surveyId: targetSurveyId,
              numberQuestionRequest: {
                questionTitle: questionPayload.questionTitle,
                minNumber: questionPayload.minNumber,
                maxNumber: questionPayload.maxNumber,
                minTitle: "",
                maxTitle: "",
              },
              isRequired: questionPayload.isRequired,
            };
            result.questions.push(numberPayload);
            break;
          }
          case "selection_question": {
            const items = questionPayload.selectionItems.map(
              (ele) => ele.itemTitle
            );
            const selectionPayload = {
              type: questionPayload.type,
              surveyId: targetSurveyId,
              selectionQuestionRequest: {
                questionTitle: questionPayload.questionTitle,
                isMultiple: questionPayload.isMultiple,
                items: items,
              },
              isRequired: questionPayload.isRequired,
            };
            result.questions.push(selectionPayload);
            break;
          }
        }
      });
      return result;
    },
    executeBulkQuestion(payload) {
      QuestionRepository.bulk(payload)
        .then(() => {
          this.$router.replace(`/surveys/${this.$route.params.id}`);
        })
        .catch(() => {
          this.snackMessage =
            "質問の追加に失敗しました。時間をおいて再度お試しください。";
          this.snackbar = true;
        });
    },
  },
  async mounted() {
    if (this.template === undefined) {
      this.$router.go(-1);
    }

    this.questions = this.template.questions;
    this.selectAllQuestions();
  },
};
</script>

<style scoped>
.template_detail_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 60px;
}
.template-detail-title {
  color: #042a2b;
  font-size: 16px;
}
</style>
