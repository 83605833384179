<template>
  <div class="floating-buttons">
    <v-btn color="primary" height="100%" width="45%" @click="addNewQuestion">
      質問を自分で<br />追加する
    </v-btn>
    <v-btn
      color="primary"
      height="100%"
      width="45%"
      @click="addNewQuestionFromTemplate"
    >
      質問をテンプレートで<br />追加する
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "AddQuestionFloatingButtons",
  components: {},
  data: () => ({}),
  computed: {},
  validations: {},
  methods: {
    addNewQuestion() {
      this.$emit("addQuestion");
    },
    addNewQuestionFromTemplate() {
      this.$emit("addQuestionFromTemplate");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.floating-buttons {
  display: flex;
  justify-content: space-evenly;

  position: fixed;
  max-width: 500px;
  height: 80px;
  margin: auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  bottom: 70px;
}
.floating-buttons button {
  font-weight: bold;
}
</style>
