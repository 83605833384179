<template>
  <v-container id="feature">
    <v-row justify="center" align="center" class="pa-2">
      <v-col cols="12" sm="4">
        <div>
          <v-img
            alt="アンケート作成イラスト"
            class="shrink feature-image"
            contain
            src="../../assets/feature-survey.png"
            max-width="330"
            height="194"
            position="bottom"
          />
        </div>
        <!-- Hide for xs, sm -->
        <div class="mt-4 feature-title-area d-none d-md-flex">
          <v-img
            alt="アンケート作成アイコン"
            src="../../assets/ic-survey.png"
            max-width="36"
          />
          <p class="ml-4 mb-0">アンケート作成</p>
        </div>
        <!-- Display for xs, sm -->
        <div class="mt-4 feature-title-area-mobile d-block d-md-none">
          <v-img
            alt="アンケート作成アイコン"
            class="shrink feature-title-area-item-mobile"
            src="../../assets/ic-survey.png"
            max-width="36"
          />
          <p class="ml-4 mb-0 feature-title-area-item-mobile">アンケート作成</p>
        </div>
        <div class="mt-4 feature-description-area">
          <p>
            <span class="feature-description-strong">
              アンケート作成機能。
            </span>
            誰でも簡単に、シンプルに、どのような要望にも答えられるようになるように目指しています。短文・長文、リストアップまで。たくさんの種類があります。
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div>
          <v-img
            alt="アンケート配信イラスト"
            class="shrink feature-image"
            contain
            src="../../assets/feature-distribute.png"
            max-width="330"
            height="194"
            position="bottom"
          />
        </div>
        <!-- Hide for xs, sm -->
        <div class="mt-4 feature-title-area d-none d-md-flex">
          <v-img
            alt="アンケート配信アイコン"
            class="shrink"
            contain
            src="../../assets/ic-distribute.png"
            max-width="36"
          />
          <p class="ml-4 mb-0">スムーズな回答依頼</p>
        </div>
        <!-- Display for xs, sm -->
        <div class="mt-4 feature-title-area-mobile d-block d-md-none">
          <v-img
            alt="アンケート配信アイコン"
            class="shrink feature-title-area-item-mobile"
            contain
            src="../../assets/ic-distribute.png"
            max-width="36"
          />
          <p class="ml-4 mb-0 feature-title-area-item-mobile">
            スムーズな回答依頼
          </p>
        </div>
        <div class="mt-4 feature-description-area">
          <p>
            <span class="feature-description-strong">
              作成後はお客様に回答してもらいましょう。
            </span>
            お客様にアンケートを依頼することは大変です。クイックアンケートなら、QRコード・メール・SNSなど、たくさんの配信方法があるので簡単です。
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div>
          <v-img
            alt="アンケート分析イラスト"
            class="shrink feature-image"
            contain
            src="../../assets/feature-analytics.png"
            max-width="330"
            height="194"
            position="bottom"
          />
        </div>
        <!-- Hide for xs, sm -->
        <div class="mt-4 feature-title-area d-none d-md-flex">
          <v-img
            alt="アンケート分析アイコン"
            class="shrink"
            contain
            src="../../assets/ic-analyze.png"
            max-width="36"
          />
          <p class="ml-4 mb-0">分析機能も充実</p>
        </div>
        <!-- Display for xs, sm -->
        <div class="mt-4 feature-title-area-mobile d-block d-md-none">
          <v-img
            alt="アンケート分析アイコン"
            class="shrink feature-title-area-item-mobile"
            contain
            src="../../assets/ic-analyze.png"
            max-width="36"
          />
          <div class="feature-title-area-item-mobile">
            <p class="ml-4 mb-0">
              分析機能も充実
            </p>
          </div>
        </div>
        <div class="mt-4 feature-description-area">
          <p>
            <span class="feature-description-strong">
              アンケート結果を分析しましょう。
            </span>
            回答を分析し、次のアクションに役立てましょう。クイックアンケートは、様々な方法でアンケートを分析することができます。アンケートのアクセスや回答数も。
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Features",
};
</script>

<style scoped>
.feature-title-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.feature-image {
  margin: 0 auto;
}
.feature-title-area-mobile {
  text-align: center;
}
.feature-title-area-item-mobile {
  display: inline-block;
  vertical-align: middle;
}
.feature-description-strong {
  color: #ee6352;
}
</style>
