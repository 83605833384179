<template>
  <div class="select_template_container">
    <v-container>
      <v-row>
        <template v-for="category in categories">
          <v-col cols="12" :key="category.title">
            <h1 class="select-template-title">{{ category.title }}</h1>
            <v-row align="stretch">
              <template v-for="questionTemplate in category.templates">
                <v-col cols="12" sm="3" :key="questionTemplate.title" class="">
                  <v-card
                    @click="handleTemplateClick(questionTemplate)"
                    class="mx-auto"
                    height="100%"
                  >
                    <v-img
                      :src="questionTemplate.image_url"
                      height="150px"
                    ></v-img>
                    <v-card-title>
                      {{ questionTemplate.title }}
                    </v-card-title>

                    <v-card-subtitle>
                      {{ questionTemplate.description }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
const ResourceRepository = RepositoryFactory.get("resource");

export default {
  name: "SelectTemplate",
  props: [],
  components: {},
  data: () => ({
    categories: [],
  }),
  computed: {},
  validations: {},
  methods: {
    fetchTemplates() {
      ResourceRepository.fetchTemplates()
        .then((res) => {
          this.categories = res.data.categories;
        })
        .catch(() => {});
    },
    handleTemplateClick(questionTemplate) {
      this.$router.push({
        name: "template_detail",
        params: {
          template: questionTemplate,
        },
      });
    },
  },
  async mounted() {
    this.fetchTemplates();
  },
};
</script>

<style scoped>
.select_template_container {
  background-color: #f6f6f6;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.select-template-title {
  color: #042a2b;
  font-size: 16px;
}
</style>
