<template>
  <div class="user_detail_container">
    <v-container>
      <v-row class="d-flex">
        <v-col cols="12">
          <h2 class="section-title mt-2 mb-3">氏名</h2>
          <v-card elevation="2">
            <v-list-item>
              <v-list-item-content>
                <div v-if="hasUserName" class="pa-0 active-title">
                  {{ user.name }}
                </div>
                <div v-else class="pa-0 inactive-title">
                  氏名を登録してください
                </div>
              </v-list-item-content>

              <v-btn @click="changeName" color="primary" text class="px-1">
                <span v-if="hasUserName">登録する</span>
                <span v-else>登録する</span>
              </v-btn>
            </v-list-item>
          </v-card>
          <h2 class="section-title mt-6 mb-3">メールアドレス</h2>
          <v-card elevation="2">
            <v-list-item>
              <v-list-item-content>
                <div class="pa-0 active-title">
                  <span
                    v-if="hasEmailConfirmed"
                    class="mr-2  pa-2 email-status-valid"
                  >
                    認証済み
                  </span>
                  <span v-else class="mr-2  pa-2 email-status-invalid">
                    未認証
                  </span>
                  {{ user.email }}
                  <v-btn
                    v-if="!hasEmailConfirmed"
                    @click="resendEmail"
                    color="primary"
                    text
                    small
                    class="px-0"
                  >
                    <span>再送</span>
                  </v-btn>
                </div>
              </v-list-item-content>
              <v-btn @click="changeEmail" color="primary" text class="px-1">
                <span>変更する</span>
              </v-btn>
            </v-list-item>
          </v-card>
          <h2 class="section-title mt-6 mb-3">パスワード</h2>
          <v-card elevation="2">
            <v-list-item>
              <v-list-item-content>
                <div class="pa-0 active-title">
                  ********
                </div>
              </v-list-item-content>
              <v-btn @click="changePassword" color="primary" text class="px-1">
                <span>変更する</span>
              </v-btn>
            </v-list-item>
          </v-card>
          <div class="quit-container mt-12 mr-4">
            <v-btn @click="deleteAccount" color="primary" text class="px-1">
              <span>退会する</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <user-name-dialog
        v-model="userNameDialog"
        :type="hasUserName ? 'edit' : 'add'"
        :userName="hasUserName ? user.name : ''"
        :refresh="fetchUserInfo"
        @close="closeUserNameDialog"
        @save="saveUserName"
      ></user-name-dialog>
      <user-password-dialog
        v-model="userPasswordDialog"
        @close="closePassword"
        @save="savePassword"
      ></user-password-dialog>
      <user-email-dialog
        v-model="emailDialog"
        @close="closeEmail"
        @save="tryToChangeEmail"
      ></user-email-dialog>
      <v-dialog v-model="deleteUserDialog" max-width="290">
        <v-card>
          <v-card-title class="headline">
            本当に退会を実行してよろしいですか？
          </v-card-title>
          <v-card-text>
            全ての質問と回答も削除されます。取り消しはできません。
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteUserDialog = false">
              キャンセル
            </v-btn>
            <v-btn color="primary" text @click="executeDeleteUser">
              確認して実行
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="2000">
        {{ snackMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
            閉じる
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { RepositoryFactory } from "../api/repositoryFactory";
import UserEmailDialog from "../components/user_detail/UserEmailDialog.vue";
import UserNameDialog from "../components/user_detail/UserNameDialog.vue";
import UserPasswordDialog from "../components/user_detail/UserPasswordDialog.vue";
const UserRepository = RepositoryFactory.get("users");

export default {
  name: "UserDetail",
  props: [],
  components: { UserNameDialog, UserPasswordDialog, UserEmailDialog },
  data: () => ({
    user: {},
    userNameDialog: false,
    deleteUserDialog: false,
    userPasswordDialog: false,
    emailDialog: false,
    snackbar: false,
    snackMessage: "",
  }),
  computed: {
    hasUserName() {
      return (
        this.user.name !== undefined &&
        this.user.name !== null &&
        this.user.name.length > 0
      );
    },
    hasEmailConfirmed() {
      return (
        this.user.emailVerified !== undefined &&
        this.user.emailVerified !== null &&
        this.user.emailVerified
      );
    },
    isPaidUser() {
      if (this.user.roles != undefined) {
        return (
          this.user.roles.filter(function(e) {
            return e.role === "PREMIUM_MEMBER";
          }).length > 0
        );
      } else {
        return false;
      }
    },
  },
  validations: {},
  methods: {
    fetchUserInfo() {
      UserRepository.me()
        .then((res) => {
          this.user = res.data.body;
          const isPaidPlan = res.data.body.roles.some(
            (c) => c.role === "PREMIUM_MEMBER" || c.role === "ADMIN"
          );
          this.$store.commit("user/upgradePlan", isPaidPlan);
        })
        .catch(() => {});
    },
    changeName() {
      this.userNameDialog = true;
    },
    changeEmail() {
      this.emailDialog = true;
    },
    closeEmail() {
      this.emailDialog = false;
    },
    tryToChangeEmail(newEmail) {
      this.emailDialog = false;
      const payload = { newEmail: newEmail };
      UserRepository.changeEmail(payload)
        .then(() => {
          this.snackbar = true;
          this.snackMessage =
            "確認メールを再送信しました。メールボックスをご確認ください。";
        })
        .catch(() => {
          this.snackbar = true;
          this.snackMessage =
            "確認メールを送信に失敗しました。時間を空けて再度お試しください。";
        });
    },
    resendEmail() {
      UserRepository.resend()
        .then(() => {
          this.snackbar = true;
          this.snackMessage =
            "確認メールを再送信しました。メールボックスをご確認ください。";
        })
        .catch(() => {
          this.snackbar = true;
          this.snackMessage =
            "確認メールを送信に失敗しました。時間を空けて再度お試しください。";
        });
    },
    changePassword() {
      this.userPasswordDialog = true;
    },
    deleteAccount() {
      this.deleteUserDialog = true;
    },
    closeUserNameDialog() {
      this.userNameDialog = false;
    },
    saveUserName(name) {
      this.userNameDialog = false;
      const payload = { name: name };
      UserRepository.edit(payload)
        .then((res) => {
          this.user = res.data.body;
          this.snackbar = true;
          this.snackMessage = "氏名の更新に成功しました！";
        })
        .catch(() => {
          this.snackbar = true;
          this.snackMessage =
            "氏名の更新に失敗しました！時間を置いて再度お試しください。";
        });
    },
    executeDeleteUser() {
      this.deleteUserDialog = false;
      UserRepository.delete()
        .then(() => {
          this.$store.commit("user/destroy");
          this.$router.replace("/");
        })
        .catch((error) => {
          if (error.response.data.header.code == "ERROR_9793") {
            this.snackMessage = "基本プランを解約後に退会をお願いします。";
            this.snackbar = true;
          } else {
            this.snackMessage =
              "退会に失敗しました。時間を置いて再度お試しください。";
            this.snackbar = true;
          }
        });
    },
    closePassword() {
      this.userPasswordDialog = false;
    },
    savePassword(payload) {
      this.userPasswordDialog = false;
      UserRepository.password(payload)
        .then(() => {
          this.snackbar = true;
          this.snackMessage = "パスワードの更新に成功しました！";
        })
        .catch(() => {
          this.snackbar = true;
          this.snackMessage =
            "パスワードに失敗しました！時間を置いて再度お試しください。";
        });
    },
    goToPay() {
      this.$router.push("/checkout");
    },
  },
  async mounted() {
    this.fetchUserInfo();

    if (this.$route.query.status === "success") {
      this.$ga.event("payment-complete", "click");
      this.snackbar = true;
      this.snackMessage = "支払い処理に成功しました！ありがとうございます！";
    }
  },
};
</script>

<style scoped>
.user_detail_container {
  background-color: #f6f6f6;
  height: 100%;
}
.section-title {
  color: #042a2b;
  font-size: 16px;
}
.active-title {
  color: #042a2b;
  font-size: 12px;
  font-weight: bold;
}
.inactive-title {
  color: #c3c3c3;
  font-size: 12px;
}
.quit-container {
  display: flex;
  justify-content: flex-end;
}
.email-status-invalid {
  background-color: #042a2b;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
.email-status-valid {
  background-color: #ee6352;
  color: #fff;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
