<template>
  <v-container>
    <v-row class="d-flex questions-container">
      <v-col cols="12" class="ma-0 pb-0">
        <v-row no-gutters>
          <v-col cols="12" sm="8" class="title-area">
            <h1 class="question-list-title ma-0">
              {{ survey.title }}
              <v-icon @click="editSurvey" color="primary">
                mdi-square-edit-outline
              </v-icon>
            </h1>
          </v-col>
          <v-col cols="12" sm="4" class="mt-2 mt-sm-0 print-button-area">
            <v-btn color="primary" @click="printSurvey">
              印刷する
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <template v-for="question in survey.questions">
        <v-col cols="12" :key="question.id" class="py-0">
          <question-item
            @click.native="goToEditQuestion(question)"
            :question="question"
          ></question-item>
        </v-col>
      </template>
    </v-row>
    <edit-question-dialog
      v-if="editingQuestion"
      v-on:close="closeDialog"
      :dialog="dialog"
      :questionType="editingQuestion.type"
      :actionType="'edit'"
      :question="editingQuestion"
      :surveyId="String(this.$route.params.id)"
    ></edit-question-dialog>
    <survey-edit-dialog
      :dialog="surveyDialog"
      :actionType="'edit'"
      :survey="survey"
      @close="closeSurveyDialog"
    ></survey-edit-dialog>
    <add-question-floating-buttons
      @addQuestion="addQuestion"
      @addQuestionFromTemplate="addQuestionFromTemplate"
    ></add-question-floating-buttons>
    <print-me :printId="printId" :survey="survey" class="d-none"></print-me>
    <v-snackbar v-model="paidSnack" :timeout="3000">
      {{ paidSnackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="goToUpgrade">
          詳しく見る
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import QuestionItem from "./QuestionItem.vue";
import EditQuestionDialog from "../../../components/question/EditQuestionDialog.vue";
import SurveyEditDialog from "./SurveyEditDialog.vue";
import AddQuestionFloatingButtons from "./AddQuestionFloatingButtons.vue";
import PrintMe from "../print/PrintMe.vue";

export default {
  name: "SurveyDetailQuestions",
  props: ["survey"],
  components: {
    QuestionItem,
    EditQuestionDialog,
    SurveyEditDialog,
    AddQuestionFloatingButtons,
    PrintMe,
  },
  data: () => ({
    dialog: false,
    surveyDialog: false,
    editingQuestion: null,
    printId: "printMe",
    paidSnack: false,
    paidSnackMessage: "",
  }),
  computed: {
    isPaidUser() {
      return this.$store.getters["user/isPaidPlan"];
    },
  },
  validations: {},
  methods: {
    goToEditQuestion(question) {
      this.editingQuestion = question;
      this.dialog = true;
    },
    closeDialog(needToRefresh) {
      if (needToRefresh) {
        this.$emit("refreshSurvey");
      }
      this.dialog = false;
    },
    closeSurveyDialog() {
      this.surveyDialog = false;
      this.$emit("refreshSurvey");
    },
    editSurvey() {
      this.surveyDialog = true;
    },
    addQuestion() {
      this.$emit("addQuestion");
    },
    addQuestionFromTemplate() {
      this.$emit("addQuestionFromTemplate");
    },
    printSurvey() {
      this.$ga.event("print-survey", "click");
      if (!this.isPaidUser) {
        this.paidSnackMessage =
          "無料プランでは、印刷機能はご利用いただけません";
        this.paidSnack = true;
        return;
      }

      const localOptions = {
        styles: ["/print/print.css"],
      };
      this.$htmlToPaper(this.printId, localOptions, () => {});
    },
    goToUpgrade() {
      this.$ga.event("open-checkout", "click", "print-limit-snack");
      this.$router.push("/checkout");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.title-area {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.question-list-title {
  color: #042a2b;
  font-size: 16px;
}
.questions-container {
  margin-bottom: 140px;
}
.print-button-area {
  text-align: right;
}
</style>
